// export const ipaddress="http://192.168.18.20:8008";
let ipaddress_tmp = "";
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    ipaddress_tmp = "http://localhost:8008";
    //ipaddress_tmp = "http://192.168.0.118:8008";
    // dev code
} else {
    ipaddress_tmp = process.env.REACT_APP_BACKEND_URL;
    // production code
}
// export const ipaddress="http://localhost:8008";
console.log("IP", ipaddress_tmp)
console.log("IP", process.env.NODE_ENV)
export const ipaddress = ipaddress_tmp;