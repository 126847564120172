import * as React from 'react';
import { Menu, MenuItem } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { Divider, IconButton, ListItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { getUsers } from '../../../../BackendFunctions/backend_api_UserService';
import { addHouse, getAppartment, addAppartment, updateAppartment, updateHouse, getHouses, deleteHouse, deleteAppartment } from '../../../../BackendFunctions/backend_api_HausverwaltungService';


import DialogRawNewHouse from './DialogWindowNewHouse';
import DisAgreeDialogRaw from './DialogWindowDisAgree';
import DialogRawNewAppartment from './DialogWindowNewAppartment';
import DialogRawEditAppartment from './DialogWindowEditAppartment';
import { Interface } from 'readline';
import APIWrapperContext from '../../../../services/APIWrapperContext';
import { HausverwaltungService } from '../../../../services/openapi';
import { UserService } from '../../../../services/openapi';


interface IpropsSpeedDialOptions {
    handleEditHouse: () => void,
    handleEditAppartment: () => void,
}




export default function SpeedDialOptions(props:IpropsSpeedDialOptions) {
    const {handleEditAppartment,handleEditHouse}= props;
    const {call_backend_api} = React.useContext(APIWrapperContext)
    

    const actions = [
        { icon: <ApartmentIcon />, name: 'Haus hinzufügen', onClickFunc: handleEditHouse },
        { icon: <MeetingRoomIcon />, name: 'Wohnung / Zimmer hinzufügen', onClickFunc: handleEditAppartment },
        // { icon: <PrintIcon />, name: 'Print' },
        // { icon: <ShareIcon />, name: 'Share' },
    ];

  


    return (
        <>
            
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.onClickFunc}
                    />
                ))}
            </SpeedDial>
            
             
        </>
    );
}
