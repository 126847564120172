import {
  Box,
  Button,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import IndicatorWorktime from "./IndicatorWorktime";
import APIWrapperContext from "../../../../../services/APIWrapperContext";
import {
  ArbeitszeitService,
  BauvorhabenWithID,
} from "../../../../../services/openapi";
import {
  addDaysToDate,
  convertDateToISODateString,
  getIsoWeekOfDate,
} from "../timehelperFunctions";
import { IWorktime } from "./interfaces";
import dayjs from "dayjs";
import { CopyAll, Update } from "@mui/icons-material";

export default function LinkGeneratorForNFC() {
  const { call_backend_api } = useContext(APIWrapperContext);
  const [bauvorhabenList, setBauvorhabenList] = useState<BauvorhabenWithID[]>(
    []
  );
  const [urlLink, setUrlLink] = useState<string>("");

  const getAll_Bauvorhaben = () => {
    call_backend_api(
      ArbeitszeitService.getAlleBauvorhabenArbeitzeitBauvorhabenGet(),
      (res: BauvorhabenWithID[]) => {
        setBauvorhabenList(res);
      },
      undefined,
      undefined,
      false
    );
  };

  useEffect(() => {
    getAll_Bauvorhaben();
  }, []);
  const [selectedBauvorhabenByUuid, setSelectedBauvorhabenByUuid] =
    useState<string>("");

  const handleChange = (event: SelectChangeEvent) => {
    setUrlLink("");
    setSelectedBauvorhabenByUuid(event.target.value);
  };

  const handleGenerateNFC = () => {
    if (bauvorhabenList && selectedBauvorhabenByUuid) {
      let link =
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? "http://localhost:3000"
          : process.env.REACT_APP_FRONTEND_URL
          ? process.env.REACT_APP_FRONTEND_URL
          : "";

      let uuid = bauvorhabenList.find(
        (el) => el.bvh_uuid === selectedBauvorhabenByUuid
      )?.bvh_uuid;
      if (uuid) {
        setUrlLink(link + "/zeiterfassung/stempeln/" + uuid);
      }
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(urlLink);
  };
  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      <Paper sx={{ display: "flex", flexDirection: "column", p: 1, m: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography variant="h4" gutterBottom>
            NFC Link Generator
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" } }}
        >
          <Box sx={{ display:'flex',flexDirection:'row' }} >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flex: 1 }} />
            <IconButton
              onClick={() => {
                getAll_Bauvorhaben();
              }}
            >
              <Update />
            </IconButton>
            <Box sx={{ flex: 1 }} />
          </Box>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Bauvorhaben
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedBauvorhabenByUuid}
              onChange={handleChange}
              label="Age"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {bauvorhabenList.map((bauvorhaben) => (
                <MenuItem value={bauvorhaben.bvh_uuid}>
                  {bauvorhaben.bvh_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Box>
          <Button
            sx={{ m: 1 }}
            variant="contained"
            onClick={() => {
              handleGenerateNFC();
            }}
          >
            Generate NFC Link
          </Button>

          {urlLink && (
            <>
              <Box
                sx={{ flex: 1, display: "flex", flexDirection: "row", m: 1 }}
              >
                <TextField
                  sx={{ flex: 1 }}
                  id="outlined-basic"
                  label="NFC Link"
                  variant="outlined"
                  value={urlLink}
                  disabled
                />
                <IconButton
                  sx={{}}
                  aria-label="delete"
                  onClick={() => {
                    handleCopyToClipboard();
                  }}
                >
                  <CopyAll />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </>
    // </ThemeProvider>
  );
}
