import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Update from "@mui/icons-material/Update";
import EditIcon from "@mui/icons-material/Edit";
import RecursiveFileListe, {
  Idir,
  Idir_Users_Userclasses,
} from "./FileList_Components";
import CloseIcon from "@mui/icons-material/Close";
import { call_backend_api } from "../services/APIWrapper";
import { DocumentsService, OpenAPI } from "../services/openapi";
import APIWrapperContext from "../services/APIWrapperContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ChipTransferList from "../Application/Widgets/ChipTransferList";
import { blue } from "@mui/material/colors";
import { Person } from "@mui/icons-material";
import DialogRawEditSharedUsers from "../Application/MainPages/SubPages/Dokumentenverwaltung/DialogWindowEditSharedUsers";
import FileUploader from "../Application/MainPages/SubPages/Dokumentenverwaltung/FileUploader";
import SearchBar from "./Search";
import { downloadFile } from "./fileListFunction";
import UpdateButton from "./UpdateButton";
import LoadingElement from "./LoadingElement";
import { DialogChooseChangeUsersOrUsergroups } from "./ChangeUsersOrUsergroups/DialogChooseChangeUsersOrUsergroups";
import EditSharedUsers from "./ChangeUsersOrUsergroups/EditSharedUsers";
import EditSharedUsergroups from "./ChangeUsersOrUsergroups/EditSharedUsergroups";
import { TonCloseEditSharedUsers, TonCloseEditSharedUsersgroups } from "../Application/MainPages/Interfaces/interfaces";

interface IFileActionsMenuProps {
  selectedDocument: Idir_Users_Userclasses | undefined;
  openDialog_EditShared:boolean, 
  setOpenDialog_EditShared:React.Dispatch<React.SetStateAction<boolean>>,
  handleUpdateDocumentList: () => void;
  openFileUploader:boolean
        closeFileUploaderFunction:()=>void
  openNewFolderDialog:boolean
        closeNewFolderDialog:(newFolderName?:string)=>void
}

export default function FileActionsMenu({
  selectedDocument,
  openDialog_EditShared=false, setOpenDialog_EditShared,
  handleUpdateDocumentList,
  openFileUploader,
        closeFileUploaderFunction,
        openNewFolderDialog,
        closeNewFolderDialog

}: IFileActionsMenuProps) {
  const { call_backend_api } = React.useContext(APIWrapperContext);

  const [newFolderName, setNewFolderName] = React.useState("");
  
  
  const [openDialog_EditSharedUsers, setOpenDialog_EditSharedUsers] =
    React.useState(false);
  const [openDialog_EditSharedUsergroups, setOpenDialog_EditSharedUsergroups] =
    React.useState(false);

  

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl_Appartment, setMobileMoreAnchorEl_Appartment] =
    React.useState(null);
 

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isMobileMenuOpen_Appartment = Boolean(mobileMoreAnchorEl_Appartment);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => {}}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          {/* <Badge badgeContent={10} color="secondary"> */}
          <EditIcon />
          {/* </Badge> */}
        </IconButton>
        <p>Bearbeiten</p>
      </MenuItem>
      <MenuItem onClick={() => {}}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <DeleteForeverIcon />
        </IconButton>
        <p>Löschen</p>
      </MenuItem>
    </Menu>
  );
  const handleMobileMenuClose_Appartment = () => {
    setMobileMoreAnchorEl_Appartment(null);
  };
  const mobileMenuId_Appartment = "primary-search-account-menu-mobile";
  const renderMobileMenu_Appartment = (
    <Menu
      anchorEl={mobileMoreAnchorEl_Appartment}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId_Appartment}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen_Appartment}
      onClose={handleMobileMenuClose_Appartment}
    >
      <MenuItem onClick={() => {}}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          {/* <Badge badgeContent={10} color="secondary"> */}
          <EditIcon />
          {/* </Badge> */}
        </IconButton>
        <p>Bearbeiten</p>
      </MenuItem>
      <MenuItem onClick={() => {}}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <DeleteForeverIcon />
        </IconButton>
        <p>Löschen</p>
      </MenuItem>
    </Menu>
  );

  const handleZwischenAuswahlClose = (value = undefined) => {
    setOpenDialog_EditShared(false);
    console.log("Zwischenauswahl",value);
    if (value && value === "Freigegeben Nutzergruppen verwalten") {
      setOpenDialog_EditSharedUsergroups(true);
    }
    if (value && value === "Freigegeben Nutzer verwalten") {
      setOpenDialog_EditSharedUsers(true);
    }
  };

  const handleClose_EditSharedUsers: TonCloseEditSharedUsers = (value) => {
    if (value && selectedDocument && selectedDocument.hidrive_id) {
      call_backend_api(
        DocumentsService.updateDocumentByIdDocUpdatePost(
          selectedDocument?.hidrive_id,
          value.map((v) => v.email)
        ),
        handleUpdateDocumentList,
        undefined,
        undefined,
        true,
        "Freigegebene Nutzer aktualisiert",
        "Fehler beim Aktualisieren der freigegebenen Nutzer"
      );

    }
    setOpenDialog_EditSharedUsers(false);
  };

  const handleClose_EditSharedUsergroups : TonCloseEditSharedUsersgroups = (value) => {
    if(value && selectedDocument && selectedDocument.hidrive_id){
        call_backend_api(
            DocumentsService.updateSharedUsergroupsDocumentByIdDocUpdateSharedUsergroupsPost(
                selectedDocument?.hidrive_id,
                value
                ),
                handleUpdateDocumentList,
        undefined,
        undefined,
        true,
        "Freigegebene Nutzergruppen aktualisiert",
        "Fehler beim Aktualisieren der freigegebenen Nutzergruppen"
            )
    
    }
    setOpenDialog_EditSharedUsergroups(false);
  }

  return (
    <>
       {renderMobileMenu}
      {renderMobileMenu_Appartment}
      <DialogChooseChangeUsersOrUsergroups
        open={openDialog_EditShared}
        onClose={handleZwischenAuswahlClose}
      ></DialogChooseChangeUsersOrUsergroups>

      <EditSharedUsers
        openDialog_EditSharedUsers={openDialog_EditSharedUsers}
        selectedDocument={selectedDocument}
        handleClose_EditSharedUsers={handleClose_EditSharedUsers}
      ></EditSharedUsers>

      {
        // Todo: Cover FileUploaders and make it work
        (selectedDocument!==undefined)&&
           <FileUploader
                onClose={closeFileUploaderFunction}
                open={openFileUploader}
                title ={"Dateien hochladen in "+selectedDocument.parent}
                dir_id={selectedDocument.hidrive_id}
            ></FileUploader> 
        
      }
      {
         <Dialog open={openNewFolderDialog} onClose={()=>closeNewFolderDialog()}>
         <DialogTitle>Neuer Ordner in {selectedDocument?.parent}</DialogTitle>
         <DialogContent>
           {/* <DialogContentText>
             To subscribe to this website, please enter your email address here. We
             will send updates occasionally.
           </DialogContentText> */}
           <TextField
             autoFocus
             margin="dense"
             id="name"
             label="Neuer Ordnername"
             type="text"
             fullWidth
             onChange={(e) => {setNewFolderName(e.target.value)}}
             variant="standard"
           />
         </DialogContent>
         <DialogActions>
           <Button onClick={()=>closeNewFolderDialog()}>Abbrechen</Button>
           <Button onClick={()=>closeNewFolderDialog(newFolderName)}>OK</Button>
         </DialogActions>
       </Dialog>
      }
      <EditSharedUsergroups
        selectedDocument={selectedDocument}
        openDialog_EditSharedUsergroups={openDialog_EditSharedUsergroups}
        handleClose_EditSharedUsergroups={handleClose_EditSharedUsergroups}
      /> 
    </>
  );
}
