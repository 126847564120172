import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Card, CardActionArea, CardContent, CardMedia, CssBaseline, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TextField } from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import UserContext from '../../../../Contexts/UserContext/UserContext';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import APIWrapperContext from '../../../../services/APIWrapperContext';
import { UserService } from '../../../../services/openapi';
//import { updateUserPW } from '../../../../BackendFunctions/backend_api_UserService';



export default function UserOptions() {
 
  const [secondary, setSecondary] = React.useState(false);
  let theme = useTheme();
  const { user_roll, email } = React.useContext(UserContext)

  const [expanded, setExpanded] = React.useState<string|boolean>(false);

  const handleChange = (panel:string) => (event:React.SyntheticEvent<Element, Event>, isExpanded:boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [values, setValues] = React.useState({
    oldPassword: '',
    newPassword1: '',
    newPassword2: '',
    email: email,

    showOldPassword: false,
    showNewPassword1: false,
    showNewPassword2: false,
  });

  const handleChangeText = (prop:string) => (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPasswordOld = () => {
    setValues({
      ...values,
      showOldPassword: !values.showOldPassword,
    });
  };
  const handleClickShowPasswordNew1 = () => {
    setValues({
      ...values,
      showNewPassword1: !values.showNewPassword1,
    });
  };
  const handleClickShowPasswordNew2 = () => {
    setValues({
      ...values,
      showNewPassword2: !values.showNewPassword2,
    });
  };

  const handleMouseDownPassword = (event:React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  

  const {call_backend_api} = React.useContext(APIWrapperContext)

  const handleClick_Change_PW = () => {
    if (values.newPassword1 === values.newPassword2) {
      call_backend_api(
        UserService.changeUsersPasswordUserMeChangePwPost(values.oldPassword,values.newPassword1),
      undefined,undefined,undefined,true,"Passwort erfolgreich geändert!","Fehler: Passwort konnte nicht geändert werden!")
      //updateUserPW(token, values.email, values.oldPassword, values.newPassword1).then(t => { console.log("change", t); setOpen(true); setSuccess_pw_changed( t == true ? true : false ) })
    }

  };
  const handleClick_Change_Email = () => {
    if (values.email&&values.email!=="" ) {
      call_backend_api(
        UserService.changeUsersEmailUserMeChangeEmailPost(values.email),
      undefined,undefined,undefined,true,"E-mail erfolgreich geändert!","Fehler: E-Mail konnte nicht geändert werden!")
      //updateUserPW(token, values.email, values.oldPassword, values.newPassword1).then(t => { console.log("change", t); setOpen(true); setSuccess_pw_changed( t == true ? true : false ) })
    }

  };
  




  

  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      <Typography variant="h2">Mein Account</Typography>
      {/* <Container component="main" > */}
      {/* <CssBaseline /> */}

      {/* This is the Main Text */}
      {/* {"E-Mail: " + String(email)} */}
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Persönliche Daten
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {"E-mail Adresse: " + String(email)}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Email ändern
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
        <Typography>
            {"Aktuelle E-Mail-Adresse: " + String(email)}
          </Typography>
        <Box sx={{ display:'flex', flexDirection: 'row' }}>
        <TextField sx={{ m: 1, width: '25ch' }}
              required
              id="outlined-required"
              label="E-Mail"
              defaultValue=''
              value={values.email}
              onChange={handleChangeText('email')}
            />
            <Box sx={{ display:'flex', flexDirection: 'column' }}>
              <Box sx={{ flex:1 }}/>
            <Button variant="contained" onClick={handleClick_Change_Email}>Email ändern</Button>
              <Box sx={{ flex:1 }}/>
            </Box>
            </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Passwort ändern</Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>
            You are currently not an owner
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails /*autoComplete="off"*/>
          <Typography>
            Ändern Sie Ihr Passwort hier ab:
          </Typography>
          <Box sx={{ flexDirection: 'column' }}>
            

            <FormControl sx={{ m: 1, width: '25ch', display: 'block' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password" required> altes Passwort</InputLabel>
              <OutlinedInput

                id="outlined-adornment-password"
                type={values.showOldPassword ? 'text' : 'password'}
                value={values.oldPassword}
                onChange={handleChangeText('oldPassword')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordOld}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="altes Passwort"
              />
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch', display: 'block' }} variant="outlined">

              <InputLabel htmlFor="outlined-adornment-password" required> neues Passwort</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showNewPassword1 ? 'text' : 'password'}
                value={values.newPassword1}
                onChange={handleChangeText('newPassword1')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordNew1}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showNewPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="neues Passwort"
              />
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch', display: 'block' }} variant="outlined">

              <InputLabel htmlFor="outlined-adornment-password" required>neues Passwort</InputLabel>

              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showNewPassword2 ? 'text' : 'password'}
                value={values.newPassword2}
                onChange={handleChangeText('newPassword2')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordNew2}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showNewPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="neues Passwort wiederholen"
              />
            </FormControl>
            <Button variant="contained" onClick={handleClick_Change_PW}>Passwort ändern</Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* {success_pw_changed &&
        // <Snackbar open={open} autoHideDuration={6000} onClose={handleClose_Snackbar}>

        //   <Alert onClose={handleClose_Snackbar} severity="success" sx={{ width: '100%' }}>
        //     Passwort erfolgreich geändert!
        //   </Alert>



        // </Snackbar>
      } */}
      {/* {!success_pw_changed &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose_Snackbar}>

          <Alert onClose={handleClose_Snackbar} severity="error" sx={{ width: '100%' }}>
            Passwortänderung nicht möglich!
          </Alert>



        </Snackbar>
      } */}
      {/* <Snackbar
        open={open} 
        autoHideDuration={6000} 
        //onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        //open={open}
        onClose={handleClose_Snackbar}
        
        key={vertical + horizontal}
      >
        {success_pw_changed &&
        <Alert onClose={handleClose_Snackbar} severity="success" sx={{ width: '100%' }}>
    Passwort erfolgreich geändert!
  </Alert>
  }
        {!success_pw_changed &&
        <Alert onClose={handleClose_Snackbar} severity="error" sx={{ width: '100%' }}>
    Passwort konnte nicht geändert werden!
  </Alert>
  }

      </Snackbar> */}

      {/* </Container> */}
      {/* </ThemeProvider> */}
    </>
  );
}
