/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableSpace } from '../models/AvailableSpace';
import type { HiDriveSyncerErrors } from '../models/HiDriveSyncerErrors';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HidriveService {

    /**
     * Setze den Hidrive Token - Admin only
     * Set HiDrive Token
     * @param hidriveToken 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateHidriveTokenHidriveSetHiDrivePost(
hidriveToken: any,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/hidrive/setHiDrive',
            query: {
                'hidrive_token': hidriveToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Erhalte aktuellen Hidrive Token - Admin only
     * Get HiDrive Token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getHidriveTokenHidriveGetHidriveGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/hidrive/getHidrive',
        });
    }

    /**
     * Aktuelle Speicherplatzübersicht - Admin only
     * Get Available Space on HiDrive
     * @returns AvailableSpace Successful Response
     * @throws ApiError
     */
    public static getHidriveAvailableSpaceHidriveGetAvailableSpaceGet(): CancelablePromise<AvailableSpace> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/hidrive/getAvailableSpace',
        });
    }

    /**
     * Endpoint um Fehler der Datensynchronisierung zu empfangen - Passwort geschützt
     * Report Error from C# Application Synchronizer
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static reportErrorFromSynchronizerHidriveReportErrorFromSynchronizerPost(
requestBody: HiDriveSyncerErrors,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/hidrive/reportErrorFromSynchronizer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
