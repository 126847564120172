import { Box, Button, Icon, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { IWorktime } from "./interfaces";
import { convertSecondsToHourMinuteString } from "../timehelperFunctions";
import { East, NorthEast, SouthEast } from "@mui/icons-material";

interface IIndicatorWorktimeProps {
  title: string;
  worktime?: IWorktime;
  worktimeBefore?: IWorktime;
}

export default function IndicatorWorktime(props: IIndicatorWorktimeProps) {
  const { title, worktime, worktimeBefore } = props;

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      <Paper
        sx={{ display: "flex", flexDirection: "column", p: 1, m: 1, flex: 1 }}
      >
        {worktime && (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ flex: 1 }}></Box>
            <Typography variant="h4" gutterBottom>
              {convertSecondsToHourMinuteString(worktime.avg)}
            </Typography>
            <Box sx={{ flex: 1 }}></Box>
          </Box>
        )}
        {worktime && worktimeBefore && (
          <>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: 1 }}></Box>
              <Typography variant="h6" gutterBottom>
                {(worktime.avg / worktimeBefore.avg) * 100 - 100}%
              </Typography>
              <Icon
                sx={{
                  color:
                    (worktime.avg / worktimeBefore.avg) * 100 - 100 > 0
                      ? "green"
                      : "red",
                }}
              >
                {(worktime.avg / worktimeBefore.avg) * 100 - 100 > 0 ? (
                  <NorthEast />
                ) : (
                  <SouthEast />
                )}
                <NorthEast />
              </Icon>
              <Box sx={{ flex: 1 }}></Box>
            </Box>
          </>
        )}
        {worktime && !worktimeBefore && (
          <>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: 1 }}></Box>
              <Typography variant="h6" gutterBottom>
                --%
              </Typography>
              <Icon>
                <East />
              </Icon>
              <Box sx={{ flex: 1 }}></Box>
            </Box>
          </>
        )}
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ flex: 1 }}></Box>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ flex: 1 }}></Box>
        </Box>
      </Paper>
    </>
    // </ThemeProvider>
  );
}
