/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PrivacyService {

    /**
     * Get the Privacy Policy
     * Return the Text
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readPrivacyPolicyPrivacyPrivacyPolicyGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/privacy/privacy-policy',
        });
    }

    /**
     * Change Privacy Policy - Admin only
     * @param newText 
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static changePrivacyPolicyPrivacyChangePrivacyPolicyPost(
newText: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/privacy/change-privacy-policy',
            query: {
                'newText': newText,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
