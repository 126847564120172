import * as React from "react";
import { Backdrop, Box, CircularProgress, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Hausliste from "./Hausliste";
import { ipaddress } from "../../../../BackendFunctions/ipconfig";
import FileListe from "../../../../List/FileList";
import FileUploader from "../Dokumentenverwaltung/FileUploader";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import APIWrapperContext from "../../../../services/APIWrapperContext";
import { UserService } from "../../../../services/openapi";
import { Edit } from "@mui/icons-material";
import { IKunde } from "./interfaces";
import EditStammdatenKunden from "./EditStammdatenKunden";

export default function StammdatenKunden() {
  const { call_backend_api } = React.useContext(APIWrapperContext);
  const [kunden, setKunden] = React.useState<Array<IKunde>>([]);
  const [selectedKunde, setSelectedKunde] = React.useState<IKunde | undefined>(
    undefined
  );
  const [open, setOpen] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const updateUsers = () => {
    setOpenBackdrop(true);
    call_backend_api(
      UserService.listUsersWithMetadataUserListWithMetadataGet(true),
      (t: IKunde[]) => setKunden(t),
      undefined,
      ()=>{setOpenBackdrop(false)},
      false
    );
  };

  React.useEffect(() => {
    updateUsers();
  }, []);

  const handleCloseDialog = (kunde: IKunde | undefined) => {
    if (kunde !== undefined) {
      Object.keys(kunde.metadata).forEach((key) => {
        console.log(key, kunde.metadata[key], selectedKunde?.metadata[key])
        if (kunde.metadata[key] !== selectedKunde?.metadata[key]) {
          call_backend_api(
            UserService.addAdditionalUserDataUserAddAdditionalUserDataPost(
              kunde.id,
              key,
              kunde.metadata[key]
            ),
            (t: any) => {
              
            },
            undefined,
            undefined,
            false
          );
        }
      });
    }
    setOpen(false);
    setSelectedKunde(undefined);
    updateUsers();
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right">Kundennummer</TableCell>
                <TableCell align="right">Ansprechpartner</TableCell>
                <TableCell align="right">Mail Ansprechpartner</TableCell>
                <TableCell align="right">Sonstiges</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {kunden.map((kunde) => (
                <TableRow
                  key={kunde.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <IconButton
                      onClick={() => {
                        setSelectedKunde(kunde);
                        setOpen(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {kunde.fullname}
                  </TableCell>
                  <TableCell align="right">
                    {kunde.metadata.Kundennummer}
                  </TableCell>
                  <TableCell align="right">
                    {kunde.metadata.Ansprechpartner}
                  </TableCell>
                  <TableCell align="right">
                    {kunde.metadata.Mail_Ansprechpartner}
                  </TableCell>
                  <TableCell align="right">
                    {Object.keys(kunde.metadata)
                      .map((key) => {
                        return String(key) + ": " + String(kunde.metadata[key]);
                      })
                      .join("; ")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditStammdatenKunden
          open={open}
          handleClose={handleCloseDialog}
          kundendetails={selectedKunde===undefined?undefined:JSON.parse(JSON.stringify(selectedKunde)) as IKunde}
        />
      </Box>
    </>
  );
}
