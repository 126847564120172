import {
  Avatar,
  AvatarGroup,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Idir_Users_Userclasses } from "../FileList_Components";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadFile, downloadFolder } from "../fileListFunction";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CloudDownload, Search } from "@mui/icons-material";
import { DocumentsService, OpenAPI } from "../../services/openapi";
import { PDFObject } from "react-pdfobject";
import APIWrapperContext from "../../services/APIWrapperContext";
import { Dir } from "fs";

export const fileActionsDownload = (
  setShowFileEmbedded: (dir: Idir_Users_Userclasses) => void,
  
) => {
  return function test(dir: Idir_Users_Userclasses,isPathHausverwaltung:boolean) {
    function Sub_test() {
      const [downloading, setDownloading] = useState<boolean>(false);
      // const downloading = false;

      const callback_setDownloading = useCallback((test: boolean) => {
        setDownloading(test);
      }, []);

      const callback_downloadFile = useCallback(() => {
        downloadFile(callback_setDownloading)(dir.hidrive_id, dir.parent);
      }, [dir]);
      const callback_downloadFolder = useCallback(() => {
        downloadFolder(callback_setDownloading)(dir.hidrive_id, dir.parent);
      }, [dir]);
      //const {call_backend_api} = useContext(APIWrapperContext)
      // const downloading = false;
      const onClick = () => {
        console.log("dir",dir);
      }

      return (
        <>
        
          {dir.type === "dir" && isPathHausverwaltung //&& dir.parent.includes("Hausverwaltung") 
          && (
            <>
            {/* {JSON.stringify(dir)} */}
            {downloading && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: "secondary",
                    position: "relative",
                    top: "1px",
                    left: "44px",
                    //zIndex: -1,
                  }}
                />
              )}
              <IconButton 
              onClick={(e)=>{
                e.stopPropagation();  
                callback_downloadFolder()
              }
              }
                color="primary"
                title="Download as Zip File"
              >
                {/* <Avatar 
                color="primary"
                  sx={{ width: 24, height: 24 }}
                > */}
                  {/* <DownloadIcon color="primary"/> */}
                  <CloudDownload color="primary"/>
                {/* </Avatar> */}
              </IconButton>
              
            </>
          )}
          {dir.type === "file" && (
            <>
              {dir.parent.toLowerCase().includes(".pdf") && (
                <>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowFileEmbedded(dir);
                    }}
                  >
                    <Search />
                  </IconButton>
                </>
              )}
              {downloading && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: "secondary",
                    position: "relative",
                    top: "1px",
                    left: "44px",
                    //zIndex: -1,
                  }}
                />
              )}
              <IconButton
                color="primary"
                aria-label="download"
                onClick={
                  callback_downloadFile
                  //() =>
                  // downloadFile(callback_setDownloading)(dir.hidrive_id, dir.parent)
                  //downloadFile(callback_setDownloading)(dir.hidrive_id, dir.parent)
                }
              >
                {<DownloadIcon />}
              </IconButton>
            </>
          )}
        </>
      );
    }
    return React.createElement(Sub_test);
  };
};
