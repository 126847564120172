import React, { ReactNode, useEffect } from 'react';


import UserContext from './UserContext';
import { useNavigate } from 'react-router-dom';
import Session, { SessionAuth, SessionContext, doesSessionExist, useSessionContext } from "supertokens-auth-react/recipe/session";
import { call_backend_api } from '../../services/APIWrapper';
import { ApiError, UserService, newUser_return } from '../../services/openapi';

interface IUserContextProviderProps {
    children?: ReactNode;

}

const UserContextProvider = ({ children }: IUserContextProviderProps) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats
    const [name, setName] = React.useState<string | undefined>(undefined);
    const [email, setEmail] = React.useState<string | undefined>(undefined);
    const [user_roll, setUserRoll] = React.useState<string[] | undefined>(undefined);
    const [checkingUser, setCheckingUser] = React.useState(true);
    let sessionContext = useSessionContext();
    // const { token,setAuthed,setCheckingAuthed } = React.useContext(SecurityContext)
    
    React.useEffect(() => {
        console.log("Check if token is there and if, get User data ")
        call_backend_api(
            UserService.readUsersMeUserMeGet(),
            (res: newUser_return) => {
                setEmail(res.email);
                setName(res.fullname);
                setUserRoll(res.userclass)
            },
            (err: ApiError) => { }
        )
        // getUser().then(t => {
        //     console.log("Token - Userdata",t);


        //     console.log("set user_roll", name, email, user_roll)

        //     // setCheckingAuthed(false)
        //     setCheckingUser(false)
        // }).catch(e=>{
        //     // setCheckingUser(false);

        //     // setCheckingAuthed(false)
        // });




    }, [(sessionContext as any).doesSessionExist])

    return (
        
        <UserContext.Provider
            value= {{
        name,
            email,
            user_roll,
            setUserRoll,
            setName,
            setEmail,
            checkingUser

    }
}
        >
    { children }
    </UserContext.Provider>
    );
};

export default UserContextProvider;
