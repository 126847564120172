import * as React from "react";
import { Box, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getRegistrationCodeForNewUser } from "../../../../BackendFunctions/backend_api_AuthService";
import { sendMailWithRegistrationCode } from "../../../../BackendFunctions/backend_api_AuthService";
import NutzerListe from "./UserList";
import { ipaddress } from "../../../../BackendFunctions/ipconfig";
import { getHouses } from "../../../../BackendFunctions/backend_api_HausverwaltungService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { preConfigUser } from "../../../../BackendFunctions/backend_api_UserService";

import {
  AuthService,
  HausverwaltungService,
} from "../../../../services/openapi";
import { SyntheticEvent } from "react";
import APIWrapperContext from "../../../../services/APIWrapperContext";
import { WidgetEmailEditor } from "../../../Widgets/EmailEditor/WidgetEmailEditor";
import ConfigureNewUser from "./ConfigureNewUser";
import UserCreationContext from "./UserCreationContext";

interface Ihaus {
  house: string;
  number: string;
  appartments: Ihaus[];
}

export default function CodeGenerator() {
  // const { token, sessionStart } = React.useContext(SecurityContext)
    const {code, setCode} = React.useContext(UserCreationContext)


  const [emailReceiver, setEmailReceiver] = React.useState("");
  const [emailMessage, setEmailMessage] = React.useState("");
  const [showSendMail, setShowSendMail] = React.useState(false);
  const [showPreConfigUser, setShowPreConfigUser] = React.useState(false);
  const [loadUsersUpdateFct, setLoadUsersUpdateFct] = React.useState(() => {});
  const [hauslist, set_hauslist] = React.useState<Ihaus[]>([]);
  const [hauslist_preConfUserAccess, set_hauslist_preConfUserAccess] =
    React.useState<Ihaus[]>([]);

  const { call_backend_api } = React.useContext(APIWrapperContext);

  interface Ihouse {
    house: string;
    appartmentname: string;
  }

  interface IGeneratedCode {
    code: string;
    status: boolean;
  }
  const handleCreateCode = () => {
    call_backend_api(
      AuthService.getNewRegistrationCodeAuthRegistrationGenerateCodeGet(),
      (t: IGeneratedCode) => setCode(t.code),
      undefined,
      undefined,
      true,
      "Code generiert",
      "Fehler beim Generieren des Codes!"
    );
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            // width: "100%",
            flexDirection: "column",
            display: "flex",
            // flex: 1,
          }}
        >
          <Box sx={{ flex: 1 }} />
          <Button variant="outlined" onClick={() => handleCreateCode()} disabled={code !== "" && code !== undefined ?true:false}>
            neuen Code generieren
          </Button>
          <Box sx={{ flex: 1 }} />
        </Box>
          <Box
            sx={{
                ml:2,
            //   width: "100%",
              flexDirection: "column",
              display: "flex",
            //   flex: 1,
            }}
          >
            <TextField
              id="outlined-basic"
              label="Code"
              variant="outlined"
              value={code !== "" && code !== undefined ? code : "---------"}
              defaultValue={"---------"}
              disabled
              sx={{ mt: 1, mb: 1 }}
            />
          </Box>
      <Box sx={{flex:1}}/>
        
      </Box>
    </>
  );
}
