import * as React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Hausliste from './Hausliste';
import { ipaddress } from '../../../../BackendFunctions/ipconfig';
import FileListe from '../../../../List/FileList';
import FileUploader from '../Dokumentenverwaltung/FileUploader';
import StammdatenKunden from './StammdatenKunden';

interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props:ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ }}>
          {/* <Typography> */}
          {children}
          {/* </Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index:number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Hausverwaltung() {
  const [secondary, setSecondary] = React.useState(false);
  const [code, setCode] = React.useState("");
  let theme = useTheme();

  const [value, setValue] = React.useState<number>(0);
  


  

  const handleChange = (event:React.SyntheticEvent<Element, Event>, newValue:number) => {
    setValue(newValue);
  };

 

  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      <Container component="main" >
        {/* <CssBaseline /> */}

      <Typography variant="h4">Hausverwaltung</Typography>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Hausverwaltung" {...a11yProps(0)} />
              <Tab label="Dokumente verwalten" {...a11yProps(1)} />
              <Tab label="Kunden Stammdatenpflege" {...a11yProps(2)} />
              {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          {/* <TabPanel value={value} index={1}>
            <Box sx={{ width: '100%' }}>
              <Button variant="contained" onClick={() => handleCreateCode()}>neuen Code generieren</Button>
            </Box>
            {code !== "" &&
              <Box sx={{ width: '100%', marginTop: 5 }}>
                <TextField id="outlined-basic" label="Code" variant="outlined" value={code} defaultValue={code} />
              </Box>
            }
          </TabPanel> */}
          <TabPanel value={value} index={0}>
          <Box sx={{ width: '100%', marginTop: 5 }}>
                <Hausliste/>
              </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
          <Box sx={{ width: '100%', marginTop: 5 }}>
                <FileListe verwaltung='hausverwaltung'/>
                {/* <FileUploader></FileUploader> */}
              </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
          <Box sx={{ width: '100%', marginTop: 5 }}>
                <StammdatenKunden/>
              </Box>
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
        </Box>


      </Container>
      {/* </ThemeProvider> */}
    </>
  );
}





