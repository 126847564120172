import React, { ReactNode, useContext } from 'react';



import { useNavigate } from 'react-router-dom';
import Session, { SessionAuth, SessionContext, doesSessionExist, useSessionContext } from "supertokens-auth-react/recipe/session";
import { call_backend_api } from '../../services/APIWrapper';
import { ApiError, ArbeitszeitService, Arbeitszeiteintrag, Bauvorhaben, BauvorhabenWithID, UserService, newUser_return } from '../../services/openapi';
import dayjs, { Dayjs } from 'dayjs';
import APIWrapperContext from '../../services/APIWrapperContext';
import ZeiterfassungDashboardContext, { IArbeitszeitenDay } from './ZeiterfassungDashboardContext';

interface IZeiterfassungContextProviderProps {
    children?: ReactNode;

}

interface Arbeitszeiteintrag_bvhID extends Omit<Arbeitszeiteintrag, 'bvh'>  {
  bvh:number
}
const getBeginningOfCurrentMonth = () => {
  let date = new Date();
  date.setDate(1);
  date.setHours(0,0,0,0);
  return date
}
const getEndOfCurrentMonth = () => {
  let date = new Date();
  date.setMonth(date.getMonth()+1);
  date.setDate(0);
  date.setHours(23,59,59,999);
  return date
}

const ZeiterfassungDashboardContextProvider = ({ children }: IZeiterfassungContextProviderProps) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats
    const {call_backend_api} = useContext(APIWrapperContext)

    const [selectedDateStart, setSelectedDateStart] = React.useState<Date>(new Date(getBeginningOfCurrentMonth()));
    const [selectedDateEnde, setSelectedDateEnde] = React.useState<Date>(new Date(getEndOfCurrentMonth()));
    const [arbeitszeiten_alle_MA, setArbeitszeiten_alle_MA] = React.useState<any>({});
    
    
    
    const [pauseZeit, setPauseZeit] = React.useState<Dayjs | null>(
      dayjs("1900-01-01T01:00")
    );
   
    const handle_Upadate_Arbeitszeiten_alle_MA = () => {
      update_Arbeitszeiten_alle_MA(selectedDateStart,selectedDateEnde)
    }
    

    const parseDateFromString = (dateString:string)=>{
      let tmpDate = new Date(Date.parse(dateString))
      tmpDate.setSeconds(0)
      tmpDate.setMilliseconds(0)
      return tmpDate
    }

    const parseDateToString = (date:Date)=>{
      return date.getFullYear() + "-" + (date.getMonth()+1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0') 
    }

    const update_Arbeitszeiten_alle_MA = async (selectedDateStart_tmp:Date,selectedDateEnde_tmp:Date) => {
      call_backend_api(ArbeitszeitService.getWorktimesOfAllZeiterfassungsUsersArbeitzeitDashboardWorktimesAllUsersGet(parseDateToString(selectedDateStart_tmp),parseDateToString(selectedDateEnde_tmp)),(res:any)=>{
        console.log("API_CAll",res)
        setArbeitszeiten_alle_MA(res)},(err:ApiError)=>{console.log(err)},undefined,true,"Daten aktualisiert","Daten konnten nicht aktualisiert werden")
    }
   


    React.useEffect(() => {
      

        
      }, []);

    

    // const { token,setAuthed,setCheckingAuthed } = React.useContext(SecurityContext)
    React.useEffect(() => {
      // Get year, month, and day part from the date
      update_Arbeitszeiten_alle_MA(selectedDateStart,selectedDateEnde)
      


      
      
      },[selectedDateStart,selectedDateEnde]);
    
      
      
      

    return (
        
        <ZeiterfassungDashboardContext.Provider
            value= {{
                selectedDateStart,
                setSelectedDateStart,
                selectedDateEnde,
                setSelectedDateEnde,
                arbeitszeiten_alle_MA,
                setArbeitszeiten_alle_MA,
                handle_Upadate_Arbeitszeiten_alle_MA
                
                

    }
}
        >
    { children }
    </ZeiterfassungDashboardContext.Provider>
    );
};

export default ZeiterfassungDashboardContextProvider;
