import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


import './index.css';
import {ThemeProvider} from '@mui/material';
//import { ThemeProvider } from '@emotion/react';
import { GlobalStyles, Box, Avatar, Container, Typography, Link, useMediaQuery } from '@mui/material';
import { theme } from './ThemeBoe';
import UserContextProvider from './Contexts/UserContext/UserContextProvider';
import { BrowserRouter } from "react-router-dom";
import AppBarContextProvider from './Contexts/AppBarContext/AppBarContextProvider';
import Application from './Application/Application';
import LockIcon from '@mui/icons-material/Lock';
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import { EmailPasswordComponentsOverrideProvider } from "supertokens-auth-react/recipe/emailpassword";
import { OpenAPI } from './services/openapi/core/OpenAPI';
import { init_supertokens } from './init_supertokens';
import { OverrideSuperTokensForm } from './Overwrite_SuperTokenForm';
import APIWrapperContextProvider from './services/APIWrapperContextProvider';
import ServiceWorkerUI from './Application/ServiceWorkerUI';

// const themeOptions = {
//   palette: {
//     type: 'light',
//     primary: {
//       main: '#357bc1',
//       contrastText: '#ffffff',
//     },
//     secondary: {
//       main: '#4b4b4a',
//     },
//     background: {
//       default: '#e3e3e3',
//     },
//   },
// };
//const theme = createTheme(themeOptions)
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // console.log("fakeID",window.location.pathname)
}
else {

  // const element = document.getElementById('fakeID')
  // console.log("fakeID", element.innerHTML)
}



OpenAPI.BASE = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://localhost:8008" : (process.env.REACT_APP_BACKEND_URL? process.env.REACT_APP_BACKEND_URL:"")
init_supertokens();



const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(

  <React.StrictMode>
    {/* <div>Test</div> */}
    {/* <UrlWrapper> */}
    <ServiceWorkerUI>

    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{
        body: { backgroundColor: "lightyellow" }
      }} />
      <SuperTokensWrapper>

        <OverrideSuperTokensForm> 

           <UserContextProvider>
            <AppBarContextProvider>
              <APIWrapperContextProvider>
              <BrowserRouter> 

                
                 <Application>

                </Application> 



              </BrowserRouter>
              </APIWrapperContextProvider>
            </AppBarContextProvider>
          </UserContextProvider> 
           </OverrideSuperTokensForm>

      </SuperTokensWrapper>
    </ThemeProvider >
      </ServiceWorkerUI>
    {/* </UrlWrapper> */}
  </React.StrictMode >,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



// // ts initial content:
// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();