import * as React from "react";
import { Box, Chip, IconButton, InputAdornment, InputBase, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getRegistrationCodeForNewUser } from "../../../../BackendFunctions/backend_api_AuthService";
import { sendMailWithRegistrationCode } from "../../../../BackendFunctions/backend_api_AuthService";
import NutzerListe from "./UserList";
import { ipaddress } from "../../../../BackendFunctions/ipconfig";
import { getHouses } from "../../../../BackendFunctions/backend_api_HausverwaltungService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { preConfigUser } from "../../../../BackendFunctions/backend_api_UserService";

import {
  AuthService,
  HausverwaltungService,
} from "../../../../services/openapi";
import { SyntheticEvent } from "react";
import APIWrapperContext from "../../../../services/APIWrapperContext";
import { WidgetEmailEditor } from "../../../Widgets/EmailEditor/WidgetEmailEditor";
import ConfigureNewUser from "./ConfigureNewUser";
import { Filter, FilterAlt } from "@mui/icons-material";
import UserCreationContext from "./UserCreationContext";

interface Ihaus {
    house: string;
    number: string;
    appartments: Ihaus[];
  }

export default function AddAppartmentsToUser() {
  // const { token, sessionStart } = React.useContext(SecurityContext)
    const {accessibleAppartments, setAccessibleAppartments} = React.useContext(UserCreationContext)
  const [hauslist, set_hauslist] = React.useState<Ihaus[]>([]);
 
  const [filter_hauslist, setFilter_hauslist] = React.useState("");

    const handle_ChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter_hauslist(event.target.value);

    }

  const { call_backend_api } = React.useContext(APIWrapperContext);

  const addAppartment_PreConfUserAccess = (appartment: Ihaus) => {
    let groupList_tmp = [...accessibleAppartments];

    groupList_tmp.push(appartment);
    //set_hauslist_preConfUserAccess(groupList_tmp);
    setAccessibleAppartments(groupList_tmp)
  };
  const removeAppartment_PreConfUserAccess = (appartment: Ihaus) => {
    let usersGroups_tmp = [...accessibleAppartments];
    usersGroups_tmp = usersGroups_tmp.filter(
      (g) => !(g.house == appartment.house && g.number == appartment.number)
    );

    //set_hauslist_preConfUserAccess(usersGroups_tmp);
    setAccessibleAppartments(usersGroups_tmp)
  };

  interface Ihouse {
    house: string;
    appartmentname: string;
  }

  const handleConfigUser = () => {
    let houseList: Ihouse[] = [];
    accessibleAppartments.forEach((el) => {
      houseList.push({
        house: el.house,
        appartmentname: el.number,
      });
    });
  };

  const getHausliste = () => {
    call_backend_api(
      HausverwaltungService.listHousesHausverwaltungHousesListGet(),
      (t: Ihaus[]) => set_hauslist(t),
      undefined,
      undefined,
      false
    );
    //getHouses().then(t => { set_hauslist(t) })
  };
  React.useEffect(() => {
    getHausliste();
  }, []);

  interface IGeneratedCode {
    code: string;
    status: boolean;
  }

  return (
    <>
      <>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Paper elevation={3} sx={{ flex: 1, p: 1 }}>
            <Typography sx={{ textDecoration: "underline" }}>
              Zugriff auf folgende Wohnungen:
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {Array.from(
                new Set(accessibleAppartments.map((el) => el.house))
              ).map((house) => (
                <>
                  <Typography>Haus: {house}</Typography>
                  <Box>
                    {accessibleAppartments
                      .filter((el) => el.house == house)
                      .map((el) => (
                        <Chip
                          sx={{ m: 0.2 }}
                          label={"Haus:" + el.house + " - Wohnung:" + el.number}
                          onDelete={() => {
                            removeAppartment_PreConfUserAccess(el);
                          }}
                        />
                      ))}
                  </Box>
                </>
              ))}
              {/* {hauslist_preConfUserAccess.map((el) => (
                <Chip
                  sx={{ m: 0.2 }}
                  label={"Haus:" + el.house + " - Wohnung:" + el.number}
                  onDelete={() => {
                    removeAppartment_PreConfUserAccess(el);
                  }}
                />
              ))} */}
            </Box>
          </Paper>
          <Paper elevation={3} sx={{ flex: 1, ml: 2, p: 1 }}>
            <Box sx={{display:'flex',flexDirection:'row'}}>
              <Box sx={{display:'flex',flexDirection:'column'}}>
              <Box sx={{flex:1}}></Box>
            <Typography sx={{ textDecoration: "underline" }}>
              Mögliche Wohnungen:
            </Typography>
            <Box sx={{flex:1}}/>
              </Box>
            <Box sx={{flex:1}}/>
            <TextField
          onChange={handle_ChangeFilter}
          id="outlined-start-adornment"
          size="small"
          sx={{  }}
          InputProps={{
            startAdornment: <InputAdornment position="start"><FilterAlt/></InputAdornment>,
          }}
        />
            
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mt:2 }}>
              {Array.from(
                new Set(
                  hauslist
                    .reduce((p: Ihaus[], c) => {
                      c.appartments.forEach((el: Ihaus) => p.push(el));
                      return p;
                    }, [])
                    .filter((el) =>
                      accessibleAppartments.reduce(
                        (p, c) =>
                          p == false
                            ? p
                            : !(el.house == c.house && el.number == c.number),
                        true
                      )
                    )
                    .filter((el)=>el.house.includes(filter_hauslist)||el.number.includes(filter_hauslist))
                    .map((el) => el.house)
                )
              ).map((house) => (
                <>
                  <Typography>Haus: {house}</Typography>
                  <Box>
                  {hauslist
                    .reduce((p: Ihaus[], c) => {
                      c.appartments.forEach((el: Ihaus) => p.push(el));
                      return p;
                    }, [])
                    .filter((el) =>
                      accessibleAppartments.reduce(
                        (p, c) =>
                          p == false
                            ? p
                            : !(el.house == c.house && el.number == c.number),
                        true
                      )
                    )
                    .filter((el) => el.house == house)
                    .filter((el)=>el.house.includes(filter_hauslist)||el.number.includes(filter_hauslist))
                    .map((el) => (
                      <Chip
                        sx={{ m: 0.2 }}
                        label={"Haus:" + el.house + " - Wohnung:" + el.number}
                        deleteIcon={<AddCircleOutlineIcon />}
                        onDelete={() => {
                          addAppartment_PreConfUserAccess(el);
                        }}
                      />
                    )
                    )
                }
                
                </Box>

                </>
              ))}

              {/* {hauslist
              .reduce((p: Ihaus[], c) => {
                c.appartments.forEach((el: Ihaus) => p.push(el));
                return p;
              }, [])
              .filter((el) =>
                hauslist_preConfUserAccess.reduce(
                  (p, c) =>
                    p == false
                      ? p
                      : !(el.house == c.house && el.number == c.number),
                  true
                )
              )
              .map((el) => (
                <Chip
                  sx={{ m: 0.2 }}
                  label={"Haus:" + el.house + " - Wohnung:" + el.number}
                  deleteIcon={<AddCircleOutlineIcon />}
                  onDelete={() => {
                    addAppartment_PreConfUserAccess(el);
                  }}
                />
              ))} */}
              {/* {JSON.stringify(hauslist)} */}
            </Box>
          </Paper>
        </Box>
        
      </>
    </>
  );
}
