import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import APIWrapperContext from "../../../services/APIWrapperContext";
import { UserService } from "../../../services/openapi";
interface IUserDetailsProps {}
//

export default function UserDetails(props: IUserDetailsProps) {
    const {call_backend_api} = React.useContext(APIWrapperContext)
    const [userDetails, setUserDetails] = React.useState<any>({})
    const [userMetaDetails, setUserMetaDetails] = React.useState<any>({})
    React.useEffect(() => {
        call_backend_api(UserService.readUsersMeUserMeGet(),(t:any)=>setUserDetails(t),undefined,undefined,false)
        call_backend_api(UserService.meAdditionalUserDataUserMeAdditionalUserDataGet(),(t:any)=>setUserMetaDetails(t),undefined,undefined,false)
    },[])

  return (
    <>
    {(userDetails.userclass /*&& userDetails.userclass.includes("Eigentümer")*/) &&(
      <Box
      sx={{
        display: "flex",
        flexDirection: {xs:"column",md:"row"},
        justifyContent: "center",
        alignItems: "left",
        //border: "2px solid gray",
        p: 2,
        mb: 2,
        mr: {xs:0.5,md:2},
        ml: {xs:0.5,md:2},
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography>Eigentümer: {userDetails.fullname}</Typography>
        {/* <Typography>Kundennummer: {userMetaDetails.Kundennummer?userMetaDetails.Kundennummer:""}</Typography> */}
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography>Ihr Ansprechpartner: {userMetaDetails.Ansprechpartner?userMetaDetails.Ansprechpartner:""}</Typography>
        <Typography>Schreiben Sie uns eine Email: {userMetaDetails.Mail_Ansprechpartner?userMetaDetails.Mail_Ansprechpartner:""}</Typography>
      </Box>
    </Box>)
    }
    </>
    // </ThemeProvider>
  );
}
