import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Card, CardMedia, IconButton, MobileStepper, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { CustomPaginationActionsTable, IAdvertisement } from './BasicTableWithPagination';
import { AdsService, OpenAPI } from '../../../services/openapi';
import APIWrapperContext from '../../../services/APIWrapperContext';



export function ConfigureAds() {
    const theme = useTheme();
    
    const {call_backend_api} = React.useContext(APIWrapperContext)
    const Haeuser = [{
        link: "https://www.boehringer.net/haeuser/details/goldammerstr-8-brackenheim/",
        img_url: "boe_goldammer_hp-beschnitt.jpg",
        description: "Goldammerstraße 8, 8/1, 12-12/5",
        location: "Brackenheim",
        status: "im Bau",
        visible: true
    },
    {
        link: "https://www.boehringer.net/wohnungen/eigentumswohnungen/details/allee-82-heilbronn/",
        img_url: "illu_allee_ansicht_nordwest__1.jpg",
        description: "Otthello - Das neue Stadthaus am Theater",
        location: "Heilbronn",
        status: "im Bau",
        visible: true
    },
    {
        link: "https://www.boehringer.net/wohnungen/eigentumswohnungen/details/sontheimer-str-38-42-besigheimer-str-47-heilbronn/",
        img_url: "boe_sontheim_illu_d1-n2_ohne_s._l_.jpg",
        description: "Sontheim Str. 38-42, Besigheimer Str.47",
        location: "Heilbronn",
        status: "im Bau",
        visible: true
    },
    {
        link: "https://www.boehringer.net/wohnungen/eigentumswohnungen/details/hauffstr-19-1-schwaigern/",
        img_url: "illu.jpg",
        description: "Hauffstr. 19/1",
        location: "Schwaigern",
        status: "im Bau",
        visible: true
    },
    {
        link: "https://www.boehringer.net/wohnungen/eigentumswohnungen/details/lindengasse-10-flein/",
        img_url: "flein_visualisierung_01_3.jpg",
        description: "Lindengasse 10+10/1",
        location: "Flein",
        status: "im Bau",
        visible: true
    },
    {
        link: "https://www.boehringer.net/wohnungen/eigentumswohnungen/details/lichtenberger-str-8-oberstenfeld/",
        img_url: "boe_oberstenfeld_hp-beschnitt.jpg",
        description: "Lichtenberger Str. 8",
        location: "Oberstenfeld",
        status: "im Bau",
        visible: true
    },

    ];

    function createData(data:IAdvertisement) {
        
        
        
        return data
      }
      //const rows = Haeuser.map((el,k)=>createData({...el,id:k} )).sort((a, b) => (a.id < b.id ? -1 : 1));
      const [rows,setRows]=React.useState<IAdvertisement[]|undefined>(undefined)
      React.useEffect(()=>{
       
          call_backend_api(AdsService.getAllAdsAdsAdsListGet(),(t : any)=>{
            setRows([...t.map((el:any,k:number)=>createData({...el,id:el.id} )).sort((a:IAdvertisement, b:IAdvertisement) => (a.id < b.id ? -1 : 1))])
          },undefined,undefined,true,"Werbung geladen","Fehler beim Laden.")
      },[])
      


    

    
    

    
   

    

    return (
        // <ThemeProvider theme={theme.palette.main}>
        <>
            <Typography variant="h4">Werbung verwalten</Typography>
                {
                    rows &&
                <CustomPaginationActionsTable rows={rows}/>
                }

            
            
        </>
        // </ThemeProvider>
    );
}






