import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import ZeiterfassungSelectBauvorhaben from "./ZeiterfassungSelectBauvorhaben";
import ZeiterfassungSelectDatum from "./ZeiterfassungSelectDatum";
import ZeiterfassungSelectArbeitszeiten from "./ZeiterfassungSelectArbeitszeiten";
import ZeiterfassungSelectDatumErfassteZeiten from "./ZeiterfassungSelectDatumErfassteZeiten";
import ZeiterfassungContextProvider from "../../../../Contexts/ZeiterfassungContext/ZeiterfassungContextProvider";
import ZeiterfassungContext from "../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext";
import ZeiterfassungNeuesBauvorhaben from "./ZeiterfassungNeuesBauvorhaben";

export default function ZeiterfassungNeueZeitmeldung() {
  
    const {createNeueZeitmeldung,setCreateNeueZeitmeldung,resetForm,sendArbeitszeit,bauvorhaben} = useContext(ZeiterfassungContext)

    const handleClick_Abbrechen=()=>{
        resetForm()
        setCreateNeueZeitmeldung(false)
    }
    const handleClick_Save=()=>{
        // after Saving and request to backend
        if (bauvorhaben.length>0){
            
          sendArbeitszeit()
          resetForm()
          setCreateNeueZeitmeldung(false)
        }
    }

  return (
    
    <>
      { createNeueZeitmeldung && <>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
          
          
          <ZeiterfassungSelectBauvorhaben          />
          <ZeiterfassungNeuesBauvorhaben />

          {
            <>
              {/* // datum: datetime = ormar.Date(nullable=False) */}
              {/* // monat: int = ormar.Integer(nullable=False)
                // jahr: int = ormar.Integer(nullable=False)
                // beginn: datetime = ormar.DateTime(nullable=False)
                // ende: datetime = ormar.DateTime(nullable=False)
                // pause: int = ormar.Integer(nullable=False) */}

              {/* // dauer: int = ormar.Integer(nullable=False) */}
              <ZeiterfassungSelectArbeitszeiten />
              {/* // kw: int = ormar.Integer(nullable=False)
                // bearbeiter: str = ormar.String(max_length=128,nullable=False)
                // kurzinfo: str = ormar.String(max_length=128,nullable=True)
                */}

              <TextField
                sx={{ m: 1 }}
                id="zeiterfassung_kurzinfo"
                label="Tätigkeit (optional)"
                variant="outlined"
                onChange={() => {}}
                />
              <Button
                sx={{ m: 1 }}
                variant="contained"
                color="primary"
                disabled={false}
                onClick={()=>{handleClick_Save()}}
                >
                Hinzufügen
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                color="primary"
                disabled={false}
                onClick={()=>{handleClick_Abbrechen()}}
                >
                Abbrechen
              </Button>
              {/*
                // notiz: str = ormar.String(max_length=128,nullable=True)
                // kst: str = ormar.String(max_length=128,nullable=True) */}
            </>
          }
        </Box>
          </>
}
    </>
    // </ThemeProvider>
  );
}
