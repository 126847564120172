import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import ZeiterfassungSelectBauvorhaben from "../ZeiterfassungSelectBauvorhaben";
import ZeiterfassungSelectDatum from "../ZeiterfassungSelectDatum";
import ZeiterfassungSelectArbeitszeiten from "../ZeiterfassungSelectArbeitszeiten";
import ZeiterfassungSelectDatumErfassteZeiten from "../ZeiterfassungSelectDatumErfassteZeiten";
import ZeiterfassungContextProvider from "../../../../../Contexts/ZeiterfassungContext/ZeiterfassungContextProvider";
import ZeiterfassungContext from "../../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext";
import ZeiterfassungNeuesBauvorhaben from "../ZeiterfassungNeuesBauvorhaben";
import ZeiterfassungNeueZeitmeldung from "../ZeiterfassungNeueZeitmeldung";

function ZeiterfassungNeuesBauvorhabenInit() {
    const {setBauvorhaben,bauvorhaben} = useContext(ZeiterfassungContext);
    
    return (
        <>
        {
            (!bauvorhaben || bauvorhaben!="Neu") && (
                
                <Button onClick={()=>{setBauvorhaben("Neu")}}>Anlegen</Button>
            )
        }
        {
            !(!bauvorhaben || bauvorhaben!="Neu") && (
                
                <Button onClick={()=>{setBauvorhaben('')}}>Abbrechen</Button>
            )
        }
        </>
    )
}


export default function BauvorhabenAnlegen() {
  const [Mandant, setMandant] = React.useState<string>("");
  const [ProjektnummerText, setProjektnummerText] = React.useState<string>("");
  const [Projektnummer, setProjektnummer] = React.useState<number>(-1);
  const [nameBauvorhaben, setNameBauvorhaben] = React.useState<string>("");

  function containsOnlyDigits(str: string) {
    return /^\d+$/.test(str);
  }

  const evalErrorMandant = (mandant: string) => {};

  const evalErrorProjektnummer = (projektnummer: string) => {
    if (projektnummer.length > 0) {
      return containsOnlyDigits(projektnummer);
    }
    return false;
  };

  const evalReadyForCreate = () => {
    if (
      Mandant.length > 0 &&
      Projektnummer > 0 &&
      nameBauvorhaben.length > 0 &&
      evalErrorProjektnummer(ProjektnummerText)
    ) {
      return true;
    }
    return false;
  };

  const handleMandant = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMandant(event.target.value);
  };
  const handleProjektnummer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjektnummerText(event.target.value);
    if (evalErrorProjektnummer(event.target.value)) {
      setProjektnummer(parseInt(event.target.value));
    }
  };
  const handleNameBauvorhaben = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNameBauvorhaben(event.target.value);
  };

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      <Paper sx={{m:1,p:1}}>
        <ZeiterfassungContextProvider>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Neues Bauvorhaben
            </Typography>
            <ZeiterfassungNeuesBauvorhaben />
            <ZeiterfassungNeuesBauvorhabenInit/>
          </Box>
        </ZeiterfassungContextProvider>
      </Paper>
    </>
    // </ThemeProvider>
  );
}
