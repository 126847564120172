import React, { useEffect } from 'react';
import AppBarContext from './AppBarContext';

interface IAppBarContextProviderProps {
    children?: React.ReactNode;
}

const AppBarContextProvider = ({ children }:IAppBarContextProviderProps) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats

    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
    

    

return (
    <AppBarContext.Provider
        value={{
            openDrawer,
            setOpenDrawer


        }}
    >
    <>
        {children}
    </>
    </AppBarContext.Provider>
);
};

export default AppBarContextProvider;
