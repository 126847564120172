import * as React from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Box,
  Checkbox,
  Grid,
  ListItemIcon,
  Paper,
  Typography,
} from "@mui/material";
import {
  IPickingListProps,
  IuserSelectable,
} from "../../Interfaces/interfaces";

export default function PickingList(props: IPickingListProps) {
  const { users, selectedUsers, owners, setListFct, ...other } = props;

  let Testusers = [
    { name: "Horst", email: "Horst", checked: false },
    { name: "Horst", email: "Horst1", checked: false },
    { name: "Horst", email: "Horst2", checked: false },
    { name: "Horst", email: "Horst3", checked: false },
    { name: "Horst", email: "Horst4", checked: false },
    { name: "Horst", email: "Horst5", checked: false },
    { name: "Horst", email: "Horst6", checked: false },
  ];
  let TestselectedUsers = [{ name: "Horst", email: "Horst", checked: false }];

  const [left, setLeft] = React.useState<IuserSelectable[]>(
    users
      ? users.filter(
          (el) => !selectedUsers.map((tu) => tu.email).includes(el.email)
        )
      : []
  );
  React.useEffect(()=>{
    setLeft(users
      ? users.filter(
          (el) => !selectedUsers.map((tu) => tu.email).includes(el.email)
        )
      : [])
  },[users])
  const [right, setRight] = React.useState(selectedUsers ? selectedUsers : []);
  React.useEffect(()=>{
    setRight(selectedUsers? selectedUsers : [])
  },[selectedUsers])

  React.useEffect(() => {
    
    setListFct(JSON.parse(JSON.stringify(right)));
  }, [right]);

  const handleClickListElement = (value: string) => {
    let leftList = left.map((el) => el.email).includes(value);
    let temp_list: IuserSelectable[] = JSON.parse(
      JSON.stringify(leftList ? left : right)
    );
    let list_element = temp_list.find((el) => el.email === value);
    if (list_element) list_element.checked = !list_element.checked;
    if (leftList) {
      setLeft(temp_list);
    } else {
      setRight(temp_list);
    }
  };

  const handleCheckedLeftToRight = () => {
    let tempList_left: IuserSelectable[] = JSON.parse(JSON.stringify(left));
    let tempList_right: IuserSelectable[] = JSON.parse(JSON.stringify(right));
    tempList_left.forEach((el) => {
      if (el.checked) {
        let temp_el = { ...el };
        temp_el.checked = false;
        tempList_right.push(temp_el);
      }
    });
    tempList_left = tempList_left.filter((el) => el.checked === false);
    setLeft(tempList_left);
    setRight(tempList_right);
  };
  const handleCheckedRightToLeft = () => {
    let tempList_left: IuserSelectable[] = JSON.parse(JSON.stringify(left));
    let tempList_right: IuserSelectable[] = JSON.parse(JSON.stringify(right));
    tempList_right.forEach((el) => {
      if (el.checked) {
        let temp_el = { ...el };
        temp_el.checked = false;
        tempList_left.push(temp_el);
      }
    });
    tempList_right = tempList_right.filter((el) => el.checked === false);
    setLeft(tempList_left);
    setRight(tempList_right);
  };

  const customList = (items: IuserSelectable[], heading: string) => (
    <Paper
      sx={{
        width: "100%",
        height: '100%',
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ marginLeft: "10px", marginTop: "10px" }}>
        {heading}
      </Typography>
      <List
        dense
        component="div"
        role="list"
        sx={{ flex: 1, overflow: "auto" }}
      >
        {items.map((value: IuserSelectable, i: number) => {
          const labelId = `transfer-list-item-${value.email}-label`;

          return (
            <ListItem
              key={value.email}
              role="listitem"
              onClick={() => {
                handleClickListElement(value.email);
                //handleToggle(value)
              }}
            >
              <ListItemIcon>
                <Checkbox
                  checked={
                    value.checked
                    // checked.indexOf(value) !== -1
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.email} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    // <Grid container spacing={0.5} justifyContent="center" alignItems="center">
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        
        {customList(left, "Plattform Benutzer")}
      </Box>
      <Box
        sx={{
          ml: 1,
          mr: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flex: 1 }}></Box>
        {/* <Box sx={{display:'flex',flexDirection:{md:'column',sx:'row'}}}> */}
        <Button
          sx={{ my: 0.5 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedLeftToRight}
          disabled={left.filter((el) => el.checked === true).length === 0}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          sx={{ my: 0.5 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedRightToLeft}
          disabled={right.filter((el) => el.checked === true).length === 0}
          aria-label="move selected left"
        >
          &lt;
        </Button>

        {/* </Box> */}
        <Box sx={{ flex: 1 }}></Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
       
        {customList(right, owners ? "Besitzer" : "Mieter")}
      </Box>
    </Box>
  );
}
