import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import ZeiterfassungContext from "../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext";

interface IZeiterfassungSelectDatum {
  
}

let options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

export default function ZeiterfassungSelectDatum(
  props: IZeiterfassungSelectDatum
) {
  const {} = props;
const {setSelectedDate,selectedDate} = React.useContext(ZeiterfassungContext);
const [showHint_Only7Days,setShowHint_Only7Days]=React.useState<boolean>(false)
  const addDays = (date: Date, days: number) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  
 
 

  const handleChangeDate = (plus: boolean) => {
    let tmpDate = selectedDate;
    let minDate = new Date(new Date().setHours(0, 0, 0, 0));
    let maxDate = new Date(new Date().setHours(0, 0, 0, 0));
    minDate = addDays(minDate, -7);
    if (plus) {
      if (tmpDate.getTime() < maxDate.getTime()) {
        tmpDate = addDays(tmpDate, 1);
      }
      
        setShowHint_Only7Days(false)
      
    } else {
      if (tmpDate.getTime() > minDate.getTime()) {
        tmpDate = addDays(tmpDate, -1);
      }
      else{
        setShowHint_Only7Days(true)
      }
    }
    setSelectedDate(tmpDate);
  };
  const stringifyDate = (date: Date) => {
    return date.toLocaleString("de-DE", options);
  }
  

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <Button variant="outlined" onClick={() => {
            handleChangeDate(false);
          }} ><ChevronLeft /></Button>
        
        <TextField
          id="zeiterfassung_datum"
          label="Datum"
          variant="outlined"
          disabled
          value={stringifyDate(selectedDate)}
          sx={{ flex: 1,ml:1,mr:1 }}
          error={showHint_Only7Days}
          helperText={
            showHint_Only7Days?"Sie können nur die letzten 7 Tage bearbeiten.":undefined
          }
        />
        <Button variant="outlined" onClick={() => {
            handleChangeDate(true);
          }} 
          
          ><ChevronRight /></Button>
      </Box>
    </Box>
  );
}
