import * as React from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';

import AppBarContext from '../../Contexts/AppBarContext/AppBarContext';
import { useMediaQuery } from '@mui/material';
const drawerWidth = 340;

const Main = styled('main', { shouldForwardProp: (prop) => true })(
    //sx={{minHeight:"100px", width:'100%'}}
    ({ theme }) => ({
      backgroundColor: 'white',//theme.palette.background.paper,
      display:'flex',
      flexDirection:'column',
      minHeight:'100vh',
      maxWidth:'100%',
      flexGrow: 1,
      zIndex:1,
      padding: useMediaQuery('(max-width:440px)')?theme.spacing(1):theme.spacing(3),
      paddingTop: theme.spacing(3),
      
      marginLeft: 0,//`-${drawerWidth}px`,
      
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),

   
      
    }),
  );

  interface IAppMainProps{
    children?: React.ReactNode;
  }


export default function AppMain(props:IAppMainProps) {
    const theme = useTheme();
    const {openDrawer ,setOpenDrawer}= React.useContext(AppBarContext)

  
    
    return (
        // <ThemeProvider theme={theme.palette.main}>
        <>
           {/* <h4>Test</h4> */}
            <Main  color={theme.palette.background.paper} >
                {props.children}
            </Main>
        </>
        // </ThemeProvider>
    );
}