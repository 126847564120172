import React, { ReactNode, useContext, useEffect } from 'react';



import { useNavigate } from 'react-router-dom';
import Session, { SessionAuth, SessionContext, doesSessionExist, useSessionContext } from "supertokens-auth-react/recipe/session";
import { call_backend_api } from '../../services/APIWrapper';
import { ApiError, ArbeitszeitService, Arbeitszeiteintrag, Bauvorhaben, BauvorhabenWithID, NotizArbeitstagUser, UserService, newUser_return } from '../../services/openapi';
import dayjs, { Dayjs } from 'dayjs';
import APIWrapperContext from '../../services/APIWrapperContext';
import ZeiterfassungDashboardContext, { IArbeitszeitenDay } from './ZeiterfassungDashboardContext';
import { convertDateToEnglishDateString, convertDateToGermanString, convertGermanDateStringToEnglishString } from '../../Application/MainPages/SubPages/Zeiterfassung/timehelperFunctions';
import ZeiterfassungDashboardNoteContext, { NotizArbeitstagUser_id } from './ZeiterfassungDashboardNoteContext';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

interface IZeiterfassungDashboardNoteContextProviderProps {
    children?: ReactNode;

}




const ZeiterfassungDashboardNoteContextProvider = ({ children }: IZeiterfassungDashboardNoteContextProviderProps) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats
    const {call_backend_api} = useContext(APIWrapperContext)

    
    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
    const [notiz, setNotiz] = React.useState<string>("");
    const [id, setId] = React.useState<number|undefined>(undefined);
    const [user_id, setUser_id] = React.useState<string>("");
    
    const [open, setOpen] = React.useState(false);

   

    const handleClose = () => {
      setId(undefined)
      setOpen(false);
    }
    
    const handleSave = () => {
      addNote();
      handleClose();
    }

    const addNote = async () => {
      if (id===-1) {
        
      let tmp_note: NotizArbeitstagUser = {
        datum: convertDateToEnglishDateString(selectedDate),
        user_id: user_id,
        notiz: notiz
      }
      
      call_backend_api(ArbeitszeitService.takeNoteOfWorktimesArbeitzeitDashboardTakeNotePost(tmp_note),()=>{},()=>{},undefined,true,"Notiz hinzugefügt","Notiz konnte nicht hinzugefügt werden")
    }
    else{
      let tmp_note: NotizArbeitstagUser = {
        datum: convertDateToEnglishDateString(selectedDate),
        user_id: user_id,
        notiz: notiz
      }
      
      call_backend_api(ArbeitszeitService.updateNoteOfWorktimesArbeitzeitDashboardUpdateNotePost(id as number,tmp_note),()=>{},()=>{},undefined,true,"Notiz geändert","Notiz konnte nicht geändert werden")

    }
    }
   
    const preSetNote = (note:NotizArbeitstagUser_id) => {
      setSelectedDate(new Date(convertGermanDateStringToEnglishString(note.datum)))
      setNotiz(note.notiz)
      setId(note.id?note.id:-1)
      setUser_id(note.user_id)
      setOpen(true)
    }


    React.useEffect(() => {
      

        
      }, []);

    

    
      
    
      
      
      

    return (
        
        <ZeiterfassungDashboardNoteContext.Provider
            value= {{
                selectedDate,
                setSelectedDate,
                notiz,
                setNotiz,
                id,
                setId,
                user_id,
                setUser_id,
                addNote,
                preSetNote
                
                

    }
}
        >
    { children }
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          
          
        }}
      >
        <DialogTitle>Notiz zum {convertDateToGermanString(selectedDate)}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Notiz"
            value={notiz}
            onChange={(e)=>{setNotiz(e.target.value)}}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined'>Abbrechen</Button>
          <Button onClick={handleSave} variant={'contained'}>Speichern</Button>
        </DialogActions>
      </Dialog>
    </ZeiterfassungDashboardNoteContext.Provider>
    );
};

export default ZeiterfassungDashboardNoteContextProvider;
