import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import IndicatorWorktime from "./IndicatorWorktime";
import APIWrapperContext from "../../../../../services/APIWrapperContext";
import { ArbeitszeitService, OpenAPI } from "../../../../../services/openapi";
import {
  addDaysToDate,
  convertDateToISODateString,
  getIsoWeekOfDate,
} from "../timehelperFunctions";
import { IWorktime } from "./interfaces";
import dayjs from "dayjs";

import { DatePicker } from "@mui/x-date-pickers";
import ZeiterfassungDashboardContext from "../../../../../Contexts/ZeiterfassungDashboardContext/ZeiterfassungDashboardContext";
import WorkingDataOverviewTable from "./WorkingDataOverviewTable";
import { Edit, Settings, Update } from "@mui/icons-material";
import ZeiterfassungDashboardNoteContextProvider from "../../../../../Contexts/ZeiterfassungDashboardContext/ZeiterfassungDashboardNoteContextProvider";

interface IMitarbeiterEintrag {
  user: any;
  setEdit: (value: string) => void;
}

function MitarbeiterEintrag(props: IMitarbeiterEintrag) {
  const { user, setEdit } = props;
  const [hoover, setHoover] = useState(false);

  return (
    <Box
      sx={{ minHeight: 40, height: 40, display: "flex", flexDirection: "row" }}
      onClick={() => {}}
      onMouseOver={() => {
        setHoover(true);
      }}
      onMouseOut={() => {
        setHoover(false);
      }}
    >
      {hoover && (
        <IconButton
          onClick={() => {
            setEdit(user.user_id);
          }}
        >
          <Edit />
        </IconButton>
      )}
      {!hoover && <Box sx={{ minWidth: 40, width: 40 }}></Box>}

      <Box sx={{ width:300, maxWidth:300, display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1 }} />
        {user.fullname ? user.fullname : user.email}
        <Box sx={{ flex: 1 }} />
      </Box>
      <Box sx={{ minWidth: 5 }}></Box>
      <Box sx={{ width:300, maxWidth:300, display: "flex", flexDirection: "column",textAlign:'center' }}>
        <Box sx={{ flex: 1 }} />
        {user.zeiterfassung_bearbeiter}
        <Box sx={{ flex: 1 }} />
      </Box>
    </Box>
  );
}

interface IWorkingDataSetBearbeiter {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function WorkingDataSetBearbeiter(
  props: IWorkingDataSetBearbeiter
) {
  const { call_backend_api } = useContext(APIWrapperContext);
  const { open, setOpen } = props;
  const [mitarbeiter, setMitarbeiter] = useState<any[]>();
  const [edit_mitarbeiter, setEditMitarbeiter] = useState<string | undefined>(
    undefined
  );
  const [tmp_kuerzel, setTmpKuerzel] = useState<string | undefined>(undefined);
  const handleClose = () => {
    setOpen(false);
  };

  const updateMitarbeiterList = () => {
    call_backend_api(
        ArbeitszeitService.getBearbeiterForUsersArbeitzeitDashboardWorkerGetBearbeiterGet(),
        (res: any) => {
          setMitarbeiter(res);
        },
        undefined,
        undefined,
        false
      );
  }

  useEffect(() => {
    if (open) {
        updateMitarbeiterList();
    }
  }, [open]);
  useEffect(() => {
    if (edit_mitarbeiter) {
        let el = mitarbeiter?.find((el) => el.user_id === edit_mitarbeiter); 
        setTmpKuerzel(el.zeiterfassung_bearbeiter)
      
    }
  },[edit_mitarbeiter])
  const handleEdit = () => {
    if (!edit_mitarbeiter || !tmp_kuerzel) {
      return;
    }
    call_backend_api(
        ArbeitszeitService.setBearbeiterForUserArbeitzeitDashboardWorkerSetBearbeiterPost(edit_mitarbeiter, tmp_kuerzel),()=>{updateMitarbeiterList()},undefined,()=>{
            setEditMitarbeiter(undefined);
    setTmpKuerzel(undefined);
        },true)
    // value verarbeiten
    
  };

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const email = formJson.email;
           
            handleClose();
          },
        }}
      >
        <DialogTitle>Bearbeiter-Kürzel definieren</DialogTitle>
        <DialogContent sx={{maxHeight:'80vh'}}>
          <DialogContentText sx={{mb:2}}>
            Fügen Sie hier das Kürzel des Bearbeiters hinzu.
          </DialogContentText>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ minWidth: 40 }}></Box>
            <Box sx={{ width:300, maxWidth:300,fontWeight:'bold' }}>Mitarbeiter/-in</Box>
            <Box sx={{ minWidth: 5 }}></Box>
            <Box sx={{ width:300, maxWidth:300, textAlign:'center',fontWeight:'bold' }}>Kürzel/Bearbeiter</Box>
          </Box>
          {mitarbeiter?.map((el) => (
            <>
              {edit_mitarbeiter !== el.user_id && (
                <>
                  <MitarbeiterEintrag user={el} setEdit={setEditMitarbeiter} />
                </>
              )}
              {edit_mitarbeiter === el.user_id && (
                <>
                  <Box sx={{ display: "flex", flexDirection: "row", backgroundColor:"lightgrey" }}>
                    <Box sx={{ minWidth: 40 }}></Box>

                    <Box
                      sx={{ width:300, maxWidth:300, display: "flex", flexDirection: "column" }}
                    >
                      <Box sx={{ flex: 1 }} />
                      {el.fullname ? el.fullname : el.email}
                      <Box sx={{ flex: 1 }} />
                    </Box>
                    <TextField
                      sx={{ flex: 1 }}
                      autoFocus
                      required
                      margin="dense"
                      id="name"
                      name="email"
                      label="Kürzel/Bearbeiter"
                      type="email"
                      value={tmp_kuerzel}
                      variant="standard"
                      onChange={(event) => {
                        setTmpKuerzel(event.target.value);
                      }}
                      inputProps={{ maxLength: 3 }}
                    />
                    <Button
                      onClick={() => {
                        handleEdit();
                      }}
                    >
                      Speichern
                    </Button>
                  </Box>
                </>
              )}
            </>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
