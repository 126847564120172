export const convertSecondsToHourMinuteString = (seconds: number) => {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}h ${minutes}m`;
}

export const convertDateToISODateString = (date: Date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");
}

export const getIsoWeekOfDate = (dateInput: Date) => {
    var date = new Date(dateInput);
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
}

export const addDaysToDate = (date:Date,daysToAdd:number)=>{
    return new Date ( new Date(date).setDate(date.getDate() + daysToAdd));
}
export const convertGermanDateStringToEnglishString = (date: string) => {
  let tmp = date.split(".");
  return tmp[2] + "-" + tmp[1] + "-" + tmp[0];
}

export const convertDateToEnglishDateString = (date: Date) => {
  return date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, "0") + "-" + String(date.getDate()).padStart(2, "0");
}

export const convertDateToGermanString = (date: Date) => {
  return String(date.getDate()).padStart(2, "0") + "." + String(date.getMonth() + 1).padStart(2, "0") + "." + date.getFullYear();
}