import * as React from 'react';
import { Box, Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileListe from '../../../../List/FileList';
import { Idir } from '../../Interfaces/interfaces';
import { Dir } from 'fs';


import { DocumentsService } from '../../../../services/openapi';
import APIWrapperContext from '../../../../services/APIWrapperContext';





export default function DeleteFolder() {
  
  const [secondary, setSecondary] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedDir, setSelectedDir] = React.useState<Idir|undefined>(undefined);
  const {call_backend_api} = React.useContext(APIWrapperContext)
  let theme = useTheme();

  const handle_folder_selected_for_delete=(dir:Idir)=>{
    
    setSelectedDir(dir)
    setOpenDialog(true)
  }
  const handleDeleteFolder = ()=>{
    if(selectedDir){
        call_backend_api(DocumentsService.deleteFolderDocDeleteFolderDelete(selectedDir.hidrive_id),undefined,undefined,undefined,true,"Ordner gelöscht!","Ordner wurde nicht gelöscht!")
    }
  }
  const handleCloseDialog =()=>{
    setSelectedDir(undefined)
    setOpenDialog(false)
  }

  return (
    <>
    <Box>

    <Typography variant="h2">Ordner löschen</Typography>
      <FileListe verwaltung="deleteFolder" verwaltung_functions={handle_folder_selected_for_delete}></FileListe>
    </Box>
    <Box sx={{flex:1}}></Box>
    <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            handleDeleteFolder()
            handleCloseDialog();
          },
        }}
      >
        <DialogTitle>Ordner löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Soll der folgende Ordner wirklich gelöscht werden?
          </DialogContentText>
          <Box>
            {selectedDir&&<>
            
                {selectedDir.parent}
            </>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Abbrechen</Button>
          <Button type="submit">Löschen</Button>
        </DialogActions>
      </Dialog>
      </>
  );
}
