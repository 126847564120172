import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight, Delete } from "@mui/icons-material";
import ZeiterfassungContext, { IArbeitszeitenDay } from "../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext";

interface IZeiterfassungSelectDatumErfassteZeiten {

}




export default function ZeiterfassungSelectDatumErfassteZeiten(
  props: IZeiterfassungSelectDatumErfassteZeiten
) {
  const {} = props;
  const {selectedDate, setSelectedDate, arbeitszeitenDay,setArbeitszeitenDay,deleteArbeitszeit,setCreateNeueZeitmeldung,createNeueZeitmeldung} = React.useContext(ZeiterfassungContext);
  
  
  const [open, setOpen] = React.useState<boolean>(false);
  const [deleteID, setDeleteID] = React.useState<number>(-1);
  const handleDelete = (id:number) => {

    setDeleteID(id)
    setOpen(true)
  }

  

  const handleClose = (deleteEintrag:boolean) => {
    if(deleteEintrag){
      deleteArbeitszeit(deleteID)
    }
    setDeleteID(-1)
    setOpen(false);

  };
  const handleNeueArbeitszeitmeldung =()=>{
    setCreateNeueZeitmeldung(true)
  }

  const parsePause = (pause:number)=>{

    let hours = Math.floor(pause/3600)
    let minutes = Math.floor((pause - hours*3600)/60)

    return (hours>9?"":"0")+String(hours) + ":" + (minutes>9?"":"0")+String(minutes)
  }

  const parseTime_toString = (datetest:Date)=>{

    return (datetest.getHours()>9?"":"0")+String(datetest.getHours()) + ":" + (datetest.getMinutes()>9?"":"0")+String(datetest.getMinutes())
    //return date.toLocaleTimeString("de-DE", {hour:"2-digit",minute:"2-digit"})
  }


  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {/* <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}> */}
      <Typography variant="h6" sx={{ml:1,mt:1}}>Erfasste Arbeitszeit: {parsePause(arbeitszeitenDay.reduce((p:number,c:IArbeitszeitenDay)=>p+((c.ende.getTime()-c.start.getTime())/1000-c.pause),0))}h</Typography>
      <List dense={true}>
              { arbeitszeitenDay.map((arbeitszeit,index) => 

                <ListItem
                sx={{borderColor:"lightgray",borderWidth:1,borderStyle:"solid",borderRadius:1,mb:1}}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={()=>{handleDelete(arbeitszeit.id)}}>
                      <Delete />
                    </IconButton>
                  }
                  >
                  
                  <ListItemText
                    primary={arbeitszeit.bauvorhaben+" " +parseTime_toString(arbeitszeit.start) + "Uhr - " + parseTime_toString(arbeitszeit.ende)+"Uhr" }
                    secondary={false ? 'Secondary text' :  "Arbeitszeit: "+parsePause((arbeitszeit.ende.getTime()-arbeitszeit.start.getTime())/1000-arbeitszeit.pause) + " Pause: " + parsePause(arbeitszeit.pause)}
                    />
                </ListItem>
              )
              
            }
              
            </List>
            {
              (!createNeueZeitmeldung) &&
              <Button variant="contained" onClick={()=>{handleNeueArbeitszeitmeldung()}}>Neue Arbeitszeitmeldung</Button>
            }
        
        
        
    {/* </Box> */}
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Arbeitszeit löschen?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Soll dieser Eintrag wirklich gelöscht werden?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleClose(false)}}>Abbrechen</Button>
          <Button onClick={()=>{handleClose(true)}} autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
