import SuperTokens from "supertokens-auth-react";





export const languageTranslations = { // This object contains all translation related options
    translations: { // These are the displayed translation strings for each language
      // The property names define the language code of the translations
      en:{ // English
        "forgotPassword": "Forgot your password?",
        "notYetRegistered": "You don't have an account? Register now.",
        "language":"en"
    }
    ,
      
      de: {
        "language":"de",
        "forgotPassword": "Passwort vergessen?",
        "notYetRegistered": "Noch kein Account? Jetzt registrieren.",
        EMAIL_VERIFICATION_RESEND_SUCCESS: "E-Mail wurde erfolgreich erneut gesendet",
        EMAIL_VERIFICATION_SUCCESS: "E-Mail wurde erfolgreich verifiziert",
        EMAIL_VERIFICATION_EXPIRED: "Der Link zur E-Mail-Verifizierung ist abgelaufen. Bitte fordern Sie einen neuen an",
        EMAIL_VERIFICATION_CONTINUE_LINK: "Erneute E-Mail-Verifizierung anfordern",

        EMAIL_PASSWORD_EMAIL_LABEL: "E-Mail",
        EMAIL_PASSWORD_EMAIL_PLACEHOLDER: "E-Mail Adresse",

        EMAIL_PASSWORD_PASSWORD_LABEL: "Passwort",
        EMAIL_PASSWORD_PASSWORD_PLACEHOLDER: "Passwort",

        EMAIL_PASSWORD_SIGN_IN_HEADER_TITLE: "LOGIN",
        EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_START: "Noch nicht registriert?",
        EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_SIGN_UP_LINK: "Registrieren",
        EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_END: "",
        EMAIL_PASSWORD_SIGN_IN_FOOTER_FORGOT_PW_LINK: "Passwort vergessen?",
        EMAIL_PASSWORD_SIGN_IN_SUBMIT_BTN: "LOGIN",
        EMAIL_PASSWORD_SIGN_IN_WRONG_CREDENTIALS_ERROR: "Falsche E-Mail- und Passwortkombination",

        EMAIL_PASSWORD_SIGN_UP_HEADER_TITLE: "REGISTRIEREN",
        EMAIL_PASSWORD_SIGN_UP_HEADER_SUBTITLE_START: "Bereits registriert?",
        EMAIL_PASSWORD_SIGN_UP_HEADER_SUBTITLE_SIGN_IN_LINK: "LOGIN",
        EMAIL_PASSWORD_SIGN_UP_HEADER_SUBTITLE_END: "",
        EMAIL_PASSWORD_SIGN_UP_FOOTER_START: "Indem Sie fortfahren, stimmen Sie unseren ",
        EMAIL_PASSWORD_SIGN_UP_FOOTER_TOS: "Nutzungsbedinungen",
        EMAIL_PASSWORD_SIGN_UP_FOOTER_AND: " und ",
        EMAIL_PASSWORD_SIGN_UP_FOOTER_PP: "Datenschutzrichtlinien",
        EMAIL_PASSWORD_SIGN_UP_FOOTER_END: "zu",
        EMAIL_PASSWORD_SIGN_UP_SUBMIT_BTN: "REGISTRIEREN",

        EMAIL_PASSWORD_EMAIL_ALREADY_EXISTS: "Diese E-Mail existiert bereits. Bitte melden Sie sich stattdessen an",

        EMAIL_PASSWORD_RESET_HEADER_TITLE: "Setzen Sie Ihr Passwort zurück",
        EMAIL_PASSWORD_RESET_HEADER_SUBTITLE: "Wir senden Ihnen eine E-Mail zum Zurücksetzen Ihres Passworts",
        EMAIL_PASSWORD_RESET_SEND_FALLBACK_EMAIL: "Ihr Benutzerkonto",
        EMAIL_PASSWORD_RESET_SEND_BEFORE_EMAIL: "Eine E-Mail zum Zurücksetzen des Passworts wurde an ",
        EMAIL_PASSWORD_RESET_SEND_AFTER_EMAIL: " gesendet, wenn diese in unserem System existiert. ",
        EMAIL_PASSWORD_RESET_RESEND_LINK: "Erneut senden oder E-Mail ändern",
        EMAIL_PASSWORD_RESET_SEND_BTN: "Sende an mich",
        EMAIL_PASSWORD_RESET_SIGN_IN_LINK: "LOGIN",

        EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_HEADER_TITLE: "Erfolgreich!",
        EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_DESC: "Ihr Passwort wurde erfolgreich aktualisiert",
        EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_SIGN_IN_BTN: "LOGIN",

        EMAIL_PASSWORD_NEW_PASSWORD_LABEL: "Neues Passwort",
        EMAIL_PASSWORD_NEW_PASSWORD_PLACEHOLDER: "Neues Passwort",
        EMAIL_PASSWORD_CONFIRM_PASSWORD_LABEL: "Passwort bestätigen",
        EMAIL_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: "Bestätigen Sie Ihr Passwort",

        EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_TITLE: "Ändern Sie Ihr Passwort",
        EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_SUBTITLE: "Geben Sie unten ein neues Passwort ein, um Ihr Passwort zu ändern",
        EMAIL_PASSWORD_RESET_SUBMIT_PW_CHANGE_PW_BTN: "PASSWORT ÄNDERN",
        EMAIL_PASSWORD_RESET_PASSWORD_INVALID_TOKEN_ERROR: "Ungültiges Token zum Zurücksetzen des Passworts",

        ERROR_EMAIL_NON_STRING: "E-Mail muss vom Typ String sein",
        ERROR_EMAIL_INVALID: "E-Mail ist ungültig",

        ERROR_PASSWORD_NON_STRING: "Passwort muss vom Typ String sein",
        ERROR_PASSWORD_TOO_SHORT: "Passwort muss mindestens 8 Zeichen enthalten, einschließlich einer Zahl",
        ERROR_PASSWORD_TOO_LONG: "Die Länge des Passworts muss kleiner als 100 Zeichen sein",
        ERROR_PASSWORD_NO_ALPHA: "Passwort muss mindestens einen Buchstaben enthalten",
        ERROR_PASSWORD_NO_NUM: "Passwort muss mindestens eine Zahl enthalten",
        ERROR_CONFIRM_PASSWORD_NO_MATCH: "Die Passwörter stimmen nicht überein",

        ERROR_NON_OPTIONAL: "Feld ist nicht optional",

        EMAIL_VERIFICATION_SEND_TITLE: "E-Mail-Verifizierung",
        EMAIL_VERIFICATION_SEND_DESC_START: "Es wurde eine Email an ihre E-Mail-Adresse gesendet. Bitte klicken Sie auf den Link in der E-Mail, um Ihre E-Mail-Adresse zu verifizieren.",
        EMAIL_VERIFICATION_SEND_DESC_STRONG:"",
        EMAIL_VERIFICATION_SEND_DESC_END: "",
        EMAIL_VERIFICATION_RESEND_BTN: "Erneut senden",
        EMAIL_VERIFICATION_LOGOUT: "Ausloggen",
        /*
         * The following are error messages from our backend SDK.
         * These are returned as full messages to preserver compatibilty, but they work just like the keys above.
         * They are shown as is by default (setting the value to undefined will display the raw translation key)
         */
        "This email already exists. Please sign in instead.": undefined,
        "Field is not optional": undefined,
        "Password must contain at least 8 characters, including a number": undefined,
        "Password's length must be lesser than 100 characters": undefined,
        "Password must contain at least one alphabet": undefined,
        "Password must contain at least one number": undefined,
        "Email is invalid": undefined,
        "Reset password link was not created because of account take over risk. Please contact support. (ERR_CODE_001)":
            undefined,
        "Cannot sign up due to security reasons. Please try logging in, use a different login method or contact support. (ERR_CODE_007)":
            undefined,
        "Cannot sign in due to security reasons. Please try resetting your password, use a different login method or contact support. (ERR_CODE_008)":
            undefined,
    },
      
    },
    /* 
     * This optional property sets the default and fallback language. 
     * It can be any string that will be used to fetch translations from the above object.
     * Defaults to "en"
     */
    defaultLanguage: "de", 
}