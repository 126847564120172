import React from 'react';

interface IAppBarContext{
    openDrawer: boolean;
    setOpenDrawer: (openDrawer: boolean) => void;
}


const defaultContext:IAppBarContext = {
    openDrawer: false,
    
    
    setOpenDrawer: () => {
        // Initial value. Replaced by context provider.
    },
    
};

const AppBarContext = React.createContext(defaultContext);

export default AppBarContext;
