import * as React from "react";

import APIWrapperContext from "../../services/APIWrapperContext";
import { UserService } from "../../services/openapi";
import {
  IuserElement,
  TonCloseEditSharedUsers,
} from "../../Application/MainPages/Interfaces/interfaces";
import DialogRawEditSharedUsers from "../../Application/MainPages/SubPages/Dokumentenverwaltung/DialogWindowEditSharedUsers";
import { Idir_Users_Userclasses } from "../FileList_Components";

interface IEditSharedUsersProps {
  openDialog_EditSharedUsers: boolean;
  selectedDocument: Idir_Users_Userclasses | undefined;
  handleClose_EditSharedUsers: TonCloseEditSharedUsers;
}

export default function EditSharedUsers({
  openDialog_EditSharedUsers,
  selectedDocument,
  handleClose_EditSharedUsers,
}: IEditSharedUsersProps) {
  const { call_backend_api } = React.useContext(APIWrapperContext);

  const [users, setUsers] = React.useState<IuserElement[]>([]);

  React.useEffect(() => {
    console.log("selectedDocument - EditSharedUsers", selectedDocument);
  }, [selectedDocument]);

  React.useEffect(() => {
    call_backend_api(
      UserService.listUsersUserListGet(),
      (t: IuserElement[]) => setUsers(t),
      ()=>console.log("error - load Users"),
      undefined,
      false
    );
      console.log("load Users")
  }, []);

  return (
    <>
        
        <DialogRawEditSharedUsers
          open={openDialog_EditSharedUsers}
          onClose={handleClose_EditSharedUsers}
          text={""}
          title={"Wohnung / Zimmer bearbeiten"}
          users={users.map((u) => {
            return { name: u.fullname, email: u.email, checked: false };
          })}
          owners={
            selectedDocument && selectedDocument.sharedUsers
              ? selectedDocument.sharedUsers.map((u: string) => {
                  return { name: u, email: u, checked: false };
                })
              : []
          }
          confirmText={"Übernehmen"}
        ></DialogRawEditSharedUsers>
      
    </>
  );
}
