import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IKunde } from "./interfaces";

interface IEditStammdatenKundenProps {
  open: boolean;
  handleClose: (kundendaten: IKunde | undefined) => void;
  kundendetails: IKunde | undefined;
}

export default function EditStammdatenKunden(
  props: IEditStammdatenKundenProps
) {
  const { open, handleClose, kundendetails } = props;
  const [form, setForm] = React.useState<any>([]);
  const [tmpKundenDetails, setTmpKundenDetails] = React.useState<any>(
    kundendetails?.metadata
  );

  React.useEffect(() => {
    if (kundendetails !== undefined) {
      setTmpKundenDetails(kundendetails.metadata);
    }
  }, [kundendetails]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: { [key: string]: string } = {};
    newValue[String(event.target.id)] = String(event.target.value);
    setTmpKundenDetails({ ...tmpKundenDetails, ...newValue });
  };
  React.useEffect(() => {
    if (tmpKundenDetails !== undefined) {
      if (!Object.keys(tmpKundenDetails).includes("Kundennummer")) {
        setTmpKundenDetails({ ...tmpKundenDetails, Kundennummer: "" });
      }
      if (!Object.keys(tmpKundenDetails).includes("Ansprechpartner")) {
        setTmpKundenDetails({ ...tmpKundenDetails, Ansprechpartner: "" });
      }

      if (!Object.keys(tmpKundenDetails).includes("Mail_Ansprechpartner")) {
        setTmpKundenDetails({ ...tmpKundenDetails, Mail_Ansprechpartner: "" });
      }
    }
  }, [tmpKundenDetails]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => handleClose(undefined)}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());

            if (kundendetails === undefined) {
              
              handleClose(undefined);
            } else {
              let tmpKunde = kundendetails;
              tmpKunde.metadata = { ...tmpKunde.metadata, ...formJson };
              console.log(tmpKunde);
              handleClose(tmpKunde);
            }
          },
        }}
      >
        <DialogTitle>Stammdaten editieren</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Editieren Sie hier die Stammdaten des Kunden
          </DialogContentText>
          {tmpKundenDetails !== undefined &&
            Object.keys(tmpKundenDetails).map((key) => (
              <TextField
                autoFocus
                // required
                margin="dense"
                id={key}
                name={key}
                label={key.replaceAll("_", " ")}
                type="text"
                value={tmpKundenDetails[key]}
                onChange={handleChange}
                fullWidth
                variant="standard"
              />
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(undefined)}>Abbrechen</Button>
          <Button type="submit">Speichern</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
