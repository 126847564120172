import * as React from "react";
import { Box, CssBaseline } from "@mui/material";
import { styled, alpha, useTheme } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import APIWrapperContext from "../../../../services/APIWrapperContext";
import { PrivacyService } from "../../../../services/openapi";

export default function Privacy() {
  const { call_backend_api } = React.useContext(APIWrapperContext);

  let theme = useTheme();
  const [text, setText] = React.useState("");
  const [changeDate, setChangeDate] = React.useState<Date|undefined>(undefined);
  //const text = "Ihre Daten werden manuell überprüft und abgeglichen um zu gewährleisten, dass auch nur  Eigentümer von Wohnanlagen in diesem Portal angemeldet sind. Bei Eigentümerwechsel  werden die Daten des ausscheidenden Eigentümers automatisch gelöscht.  Auf Registrierung von Dritten wird nicht reagiert. Wenn Sie sich registriert haben und von  uns keine Rückmeldung erhalten haben, so bitten wir Sie sich bei uns zu melden  (info@böhringer.net).  Wir nehmen den Schutz Ihrer Daten sehr ernst, daher geben wir Ihre persönlichen Daten  einschließlich Ihrer Anschrift und E-Mail-Adresse nicht an Dritte weiter.  Darüber hinaus können Sie auf unserer Internetseite nachlesen wie wir mit dem Thema  Datenschutz umgehen."

  React.useEffect(() => {
    call_backend_api(
      PrivacyService.readPrivacyPolicyPrivacyPrivacyPolicyGet(),
      (res:any) => {
        setText(res.text);
        setChangeDate(new Date(res.date));
      },
      () => {
        setText("Fehler beim Laden der Datenschutzerklärung");
      },
      undefined,
      false
    );
    //setText
  }, []);

  return (
    <>
      <Typography variant="h2">Datenschutz</Typography>
      <Box dangerouslySetInnerHTML={{__html: text}} />
      {/* {text.split("<br>").map((t, i) => (
        <Typography key={i}>{t}</Typography>
      ))} */}
        <Typography variant="h6">
            Letzte Änderung: {changeDate?.toLocaleDateString()}
            </Typography>
    </>
  );
}
