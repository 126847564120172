import { ipaddress } from "./ipconfig";
import { OpenAPI } from "../services/openapi/core/OpenAPI.ts";
import { UserService } from "../services/openapi/services/UserService.ts";
import { DocumentsService } from "../services/openapi/services/DocumentsService.ts";
import { SecurityService } from "../services/openapi/services/SecurityService.ts";
import { AuthService } from "../services/openapi/services/AuthService.ts";
import { HausverwaltungService } from "../services/openapi/services/HausverwaltungService.ts";

export const getEndpointList = async (token) => {
    OpenAPI.TOKEN = token
    // let url = ""+ipaddress+"/HiDrive/getFiles/User";
    // console.log(url)
    const response = SecurityService.provideEndpointListSecurityEndpointListGet()
    let test = await response
    console.log("User Files", test)
    return test
}

export const addUsergroupToEndpoint = async (usergroup, endpoint) => {
    const response = await SecurityService.addUsergroupToEndpointSecurityAddUsergroupPost(endpoint, usergroup)
    return response
}

export const removeUsergroupFromEndpoint = async (usergroup, endpoint) => {
    const response = await SecurityService.removeUsergroupToEndpointSecurityRemoveUsergroupDelete(endpoint, usergroup)
    return response
}