import { Add } from "@mui/icons-material";
import { Box, Chip, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";

export interface FilterOption {
  column: "severity" | "timestamp" | "message";
  value: string;
  operator: "includes" | "not includes";
}
interface IFilter {
  updateFilters?: Function;
  showFilterOptions: boolean;
}

export function Filter(props: IFilter) {
  const { showFilterOptions, updateFilters = (f: FilterOption[]) => {} } =
    props;
  const [filters, setFilters] = React.useState<FilterOption[]>([]);
  const [filterText, setFilterText] = React.useState<string>("");
  useEffect(() => {
    updateFilters(filters);
  }, [filters]);
  const addFilter = () => {
    setFilters([
      ...filters,
      {
        column: "message",
        value: filterText,
        operator: "includes",
      },
    ]);
  };
  const removeFilter = (key: number) => {
    setFilters(filters.filter((_, k) => k !== key));
  };
  return (
    <>
      <Box sx={{ m: 2 }}>
        {showFilterOptions && (
          <Box>
            <Typography variant="h6">Filter Optionen:</Typography>
            <TextField size="small" 
              onChange={(e) => setFilterText(String(e.target.value))}
            />
            <IconButton
              onClick={() => {
                addFilter();
              }}
            >
              <Add></Add>
            </IconButton>
          </Box>
        )}
        <Box>
          {filters.map((f, key) => (
            <Chip
              sx={{ m: 0.2 }}
              label={f.value}
              onDelete={() => {
                removeFilter(key);
              }}
            />
          ))}
        </Box>
      </Box>
    </>
  );
}
