import * as React from "react";
import { Box, Checkbox, Chip, FormControlLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getRegistrationCodeForNewUser } from "../../../../BackendFunctions/backend_api_AuthService";
import { sendMailWithRegistrationCode } from "../../../../BackendFunctions/backend_api_AuthService";
import NutzerListe from "./UserList";
import { ipaddress } from "../../../../BackendFunctions/ipconfig";
import { getHouses } from "../../../../BackendFunctions/backend_api_HausverwaltungService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { preConfigUser } from "../../../../BackendFunctions/backend_api_UserService";

import {
  AuthService,
  HausverwaltungService,
} from "../../../../services/openapi";
import { SyntheticEvent } from "react";
import APIWrapperContext from "../../../../services/APIWrapperContext";
import { WidgetEmailEditor } from "../../../Widgets/EmailEditor/WidgetEmailEditor";
import ConfigureNewUser from "./ConfigureNewUser";
import UserCreationContext from "./UserCreationContext";

interface Ihaus {
  house: string;
  number: string;
  appartments: Ihaus[];
}

export default function AddUserInformation() {
  // const { token, sessionStart } = React.useContext(SecurityContext)
    const {name, setName,kundennummer,setKundennummer,isOwner,setIsOwner,Ansprechpartner,setAnsprachpartner,Mail_Ansprechpartner,setMail_Ansprachpartner} = React.useContext(UserCreationContext)

  const handleChangeAnsprechpartner = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnsprachpartner(event.target.value);
  }
  const handleChangeMailAnsprechpartner = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMail_Ansprachpartner(event.target.value);
  }

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }
  const handleChangeKundennummer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKundennummer(event.target.value);
  }
  const handleChangeIsOwner = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked)
    setIsOwner(event.target.checked);
  }

  const { call_backend_api } = React.useContext(APIWrapperContext);

  interface Ihouse {
    house: string;
    appartmentname: string;
  }

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
          onChange={handleChangeName}
            label="Name"
            id="fullnameOfUser"
            defaultValue=""
            size="small"
            value={name}
          />
          <FormControlLabel  control={<Checkbox onChange={handleChangeIsOwner} checked={isOwner}/>} label={"Benutzer ist Eigentümer"} />
          {
            isOwner &&(
              <>
              
              <TextField
              onChange={handleChangeKundennummer}
              label="Kundennummer"
              id="fullnameOfUser"
              defaultValue=""
              size="small"
              sx={{mt:1}}
              value={kundennummer}
              />
              <TextField
              onChange={handleChangeAnsprechpartner}
              label="Ansprechpartner"
              id="ansprechpartner"
              defaultValue=""
              size="small"
              sx={{mt:1}}
              value={Ansprechpartner}
              />
              <TextField
              onChange={handleChangeMailAnsprechpartner}
              label="Mail Ansprechpartner"
              id="mail_ansprechpartner"
              defaultValue=""
              size="small"
              sx={{mt:1}}
              value={Mail_Ansprechpartner}
              />
              </>
              
              )
            }
        </Box>
        <Box sx={{ flex: 1 }} />
      </Box>
    </>
  );
}
