/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_createAppartment_hausverwaltung_appartments_create_post } from '../models/Body_createAppartment_hausverwaltung_appartments_create_post';
import type { Body_updateAppartmentByName_hausverwaltung_appartments_update_put } from '../models/Body_updateAppartmentByName_hausverwaltung_appartments_update_put';
import type { Body_upload_file_hausverwaltung_addFileToHausverwaltung_post } from '../models/Body_upload_file_hausverwaltung_addFileToHausverwaltung_post';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HausverwaltungService {

    /**
     * Liste von allen Häusern - Admins only
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listHousesHausverwaltungHousesListGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/hausverwaltung/houses/list',
        });
    }

    /**
     * Erstelle neues Haus - Hausverwaltung only
     * @param name 
     * @param address 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createHouseHausverwaltungHousesCreatePost(
name: string,
address: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/hausverwaltung/houses/create',
            query: {
                'name': name,
                'address': address,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Haus - Hausverwaltung only
     * @param name 
     * @param attr 
     * @param val 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateHouseByNameHausverwaltungHousesUpdatePut(
name: string,
attr: string,
val: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/hausverwaltung/houses/update',
            query: {
                'name': name,
                'attr': attr,
                'val': val,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Lösche Haus - Hausverwaltung only
     * @param name 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteHouseByNameHausverwaltungHousesDeleteDelete(
name: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/hausverwaltung/houses/delete',
            query: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Liste von allen Appartments - Hausverwaltung only
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listAppartmentsHausverwaltungAppartmentsListGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/hausverwaltung/appartments/list',
        });
    }

    /**
     * Get Appartment Details - Hausverwaltung only
     * @param number 
     * @param house 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAppartmentsHausverwaltungAppartmentsGetAppartmentGet(
number: any,
house: any,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/hausverwaltung/appartments/getAppartment',
            query: {
                'number': number,
                'house': house,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Erstelle neues Appartment - Hausverwaltung only
     * @param number 
     * @param housename 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createAppartmentHausverwaltungAppartmentsCreatePost(
number: string,
housename: string,
requestBody?: Body_createAppartment_hausverwaltung_appartments_create_post,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/hausverwaltung/appartments/create',
            query: {
                'number': number,
                'housename': housename,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Appartment - Hausverwaltung only
     * @param name 
     * @param house 
     * @param attr 
     * @param newname 
     * @param newhouse 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateAppartmentByNameHausverwaltungAppartmentsUpdatePut(
name: string,
house: string,
attr: string,
newname: string,
newhouse: string,
requestBody?: Body_updateAppartmentByName_hausverwaltung_appartments_update_put,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/hausverwaltung/appartments/update',
            query: {
                'name': name,
                'house': house,
                'attr': attr,
                'newname': newname,
                'newhouse': newhouse,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Lösche Appartment - Hausverwaltung only
     * @param number 
     * @param house 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteAppartmentByNameHausverwaltungAppartmentsDeleteGet(
number: string,
house: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/hausverwaltung/appartments/delete',
            query: {
                'number': number,
                'house': house,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Füge Datei zu Ordner hinzu - only Hausverwaltung
     * @param formData 
     * @param notifyUsers 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static uploadFileHausverwaltungAddFileToHausverwaltungPost(
formData: Body_upload_file_hausverwaltung_addFileToHausverwaltung_post,
notifyUsers: boolean = false,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/hausverwaltung/addFileToHausverwaltung',
            query: {
                'notifyUsers': notifyUsers,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
