import * as React from 'react';
import { Box, CssBaseline } from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';






export default function Faqs() {
  
  const [secondary, setSecondary] = React.useState(false);
  let theme = useTheme();

  const generate=()=>{
    let faqs = [
      {q:"Was ist die Böhringer App?",a:"Die Böhringer App stellt Ihnen Ihre Unterlagen und Dokumente digital zur Verfügung."},
      {q:"Wie kann ich meine Unterlagen und Dokumente einsehen?",a:"Öffnen Sie die Startseite. Suchen Sie in der Verzeichnisstruktur das gewünschte Dokument. Per Klick auf den blauen Peil startet der Download."},
      {q:"Wie kann ich mich ausloggen?",a:"Sie können sich per Klick auf den Avatar rechts oben unter 'Logout' ausloggen."},
      {q:"Ich habe mein Zugangsdaten vergessen. Wie kann ich diese zurücksetzen?",a:"Bitte verwenden Sie die Funktion Passwort zurücksetzen auf der Login-Seite."},
      {q:"An wen kann ich mich wenden, wenn Fehler auftreten?",a:"Bitte wenden Sie sich an Ihren Ansprechpartner aus unserem Team."}
    ];
    return faqs.map(el=>
      <Accordion  sx={{
        marginTop:"16px"
      }} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            bgcolor: 'primary.main',
          //  borderTopLeftRadius:5,
          //  borderTopRightRadius:5,
           color:'primary.contrastText'
              
            
          }}
        >
          <Typography>{el.q}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{
            // bgcolor: 'primary.main',
          //  borderTopLeftRadius:5,
          //  borderTopRightRadius:5,
          //  color:'primary.contrastText'
              
            
          }}>
          <Typography>
            {el.a}
          </Typography>
        </AccordionDetails>
      </Accordion>
    )

  }

  return (
    <>
    <Typography variant="h2">FAQ</Typography>
      {generate()}
      </>
  );
}
