import React from 'react';

interface UserContextInterface {
    name: string |undefined;
    email: string |undefined;
    user_roll:string[]|undefined;
    checkingUser?:boolean;
    setName: (name: string) => void;
    setEmail: (email: string) => void;
    setUserRoll:(user_roll:string[])=>void;

}


const defaultContext: UserContextInterface = {
    name: undefined,
    email: undefined,
    user_roll:["normal_user"],
    checkingUser:true,
    setUserRoll: ()=>{

    },
    
    setName: () => {
        // Initial value. Replaced by context provider.
    },
    setEmail: () => {
        // Initial value. Replaced by context provider.
    },
    
};

const UserContext = React.createContext<UserContextInterface>(defaultContext);

export default UserContext;
