import List from "@mui/material/List";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { Close, Person } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { OpenAPI } from "../../services/openapi";
import { PDFObject } from "react-pdfobject";

interface IDialogDeleteFileProps {
  onClose: Function;
  open: boolean;
  filename: string;
}

export function DialogDeleteFile({ onClose, open,filename }: IDialogDeleteFileProps) {
  const handleClose = () => {
   
    onClose();
  };
  const handleDeleteClose = () => {
   
    onClose(true);
  };

  
  useEffect(() => {
    
    
  }, [ open]);
  useEffect(() => {}, [open]);

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      open={open}
      maxWidth={"md"}
      style={{
        
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DialogTitle>Datei löschen</DialogTitle>
      <DialogContent sx={{ display:'flex', flexDirection:'column' }}>
        {"Soll die Datei "+filename+" wirklich gelöscht werden?"}
        
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>Abbrechen</Button>
        <Button variant="contained" onClick={handleDeleteClose}>Löschen</Button>
      </DialogActions>
    </Dialog>
  );
}
