import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

interface IDisAgreeDialogRawProps{
    onClose:(newValue?:string[]|boolean|undefined,action?:string|undefined)=>void,
    open:boolean,
    title:string,
    text:string,
    action:string,
    id?:string,
    init_value?:boolean,
}


export default function DisAgreeDialogRaw(props:IDisAgreeDialogRawProps) {
  const { onClose,  open, title, text, action,init_value, ...other } = props;
  const [value, setValue] = React.useState<boolean|undefined>(init_value?init_value:undefined);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (!open) {
      setValue(undefined);
    }
  }, [ open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value,action);
  };


  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
      id={props.id}
      keepMounted
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Nein</Button>
          <Button onClick={handleOk} autoFocus>
            Ja
          </Button>
        </DialogActions>
      
    </Dialog>
  );
}

