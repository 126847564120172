import * as React from "react";
import { Box, Checkbox, Chip, FormControlLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getRegistrationCodeForNewUser } from "../../../../BackendFunctions/backend_api_AuthService";
import { sendMailWithRegistrationCode } from "../../../../BackendFunctions/backend_api_AuthService";
import NutzerListe from "./UserList";
import { ipaddress } from "../../../../BackendFunctions/ipconfig";
import { getHouses } from "../../../../BackendFunctions/backend_api_HausverwaltungService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { preConfigUser } from "../../../../BackendFunctions/backend_api_UserService";

import {
  AuthService,
  HausverwaltungService,
} from "../../../../services/openapi";
import { SyntheticEvent } from "react";
import APIWrapperContext from "../../../../services/APIWrapperContext";
import { WidgetEmailEditor } from "../../../Widgets/EmailEditor/WidgetEmailEditor";
import ConfigureNewUser from "./ConfigureNewUser";
import UserCreationContext from "./UserCreationContext";

interface Ihaus {
  house: string;
  number: string;
  appartments: Ihaus[];
}

export default function NewUserSummary() {
  // const { token, sessionStart } = React.useContext(SecurityContext)
    const {name, kundennummer,isOwner,accessibleAppartments,userGroups,code,Mail_Ansprechpartner,Ansprechpartner} = React.useContext(UserCreationContext)
  

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1, }}>
            Zusammenfassung
        </Typography>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1,mt:2 }}>
            Registrierungscode
        </Typography>
        <Typography  component="div" sx={{ flexGrow: 1,ml:2 }}>
            Code: {code}
        </Typography>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1,mt:2 }}>
            User Details
        </Typography>
        <Typography  component="div" sx={{ flexGrow: 1,ml:2 }}>
            Name: {name}
        </Typography>
        <Typography  component="div" sx={{ flexGrow: 1,ml:2 }}>
            Ist Eigentümer: {isOwner ? "Ja" : "Nein"}
        </Typography>
        {
            isOwner && (
                <>
            <Typography  component="div" sx={{ flexGrow: 1,ml:2 }}>
            Kundenummer: {kundennummer}
        </Typography>
            <Typography  component="div" sx={{ flexGrow: 1,ml:2 }}>
            Ansprechpartner: {Ansprechpartner}
        </Typography>
            <Typography  component="div" sx={{ flexGrow: 1,ml:2 }}>
            Mail Ansprechpartner: {Mail_Ansprechpartner}
        </Typography>
                </>
            )
        }
        {
            isOwner && (
                <>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1,mt:2 }}>
            Wohnungen
        </Typography>
        {accessibleAppartments.map((appartment) => 
        <Typography  component="div" sx={{ flexGrow: 1,ml:2 }}>
             {appartment.number} ({appartment.house})
        </Typography>
        )}
        {
            accessibleAppartments.length == 0 && (
                <Typography  component="div" sx={{ flexGrow: 1,ml:2 }}>
                    Keine Wohnungen zugewiesen
                </Typography>
            )
        }
                </>
            )
        }
        {
            (!isOwner) && (
                <>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1,mt:2 }}>
            Usergruppen
        </Typography>
        {userGroups.map((ug) => 
        <Typography  component="div" sx={{ flexGrow: 1,ml:2 }}>
            {ug} 
        </Typography>
        )}
        {
            userGroups.length == 0 && (
                <Typography  component="div" sx={{ flexGrow: 1,ml:2 }}>
                    Keine Usergruppen zugewiesen
                </Typography>
            )
        }
                </>
            )
        }
        
      </Box>
    </>
  );
}
