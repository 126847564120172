import * as React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Container,
  Typography,
  MenuItem,
  Divider,
  TextField,
  IconButton,
  Button,
  SelectChangeEvent,
  Chip,
  Tooltip,
} from "@mui/material";
import ChipTransferList from "../../../Widgets/ChipTransferList";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import APIWrapperContext from "../../../../services/APIWrapperContext";
import {
  getEndpointList,
  removeUsergroupFromEndpoint,
  addUsergroupToEndpoint,
} from "../../../../BackendFunctions/backend_api_SecurityService";
import { getUserGroups } from "../../../../BackendFunctions/backend_api_UserService";
import { Iusergroup } from "../../Interfaces/interfaces";
import { SecurityService, UserService } from "../../../../services/openapi";
import ChipTransferList_EndpointSelection from "../../../Widgets/ChipTransferList_EndpointSelection";
import ChipTransferListEndpointSelection from "../../../Widgets/ChipTransferList_EndpointSelection";
import { Add, Close, InfoOutlined, Save } from "@mui/icons-material";

interface IUsergroupSelectProps {
  classnames: string[];
  setSelectedClass: (classname: string) => void;
}

function UsergroupSelect({
  classnames,
  setSelectedClass = () => {},
}: IUsergroupSelectProps) {
  const [classname, setClassname] = React.useState("");

  const handleChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    setClassname(event.target.value);
  };
  React.useEffect(() => {
    setSelectedClass(classname);
  }, [classname]);

  console.log(classnames);
  return (
    <Box>
      <FormControl fullWidth sx={{ m: 1 }}>
        <InputLabel id="demo-simple-select-label">Usergroup</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={classname}
          label="Usergroup"
          onChange={handleChange}
        >
          {classnames.map((c) => (
            <MenuItem value={c}>{c}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
interface Iendpoint_small {
  end_func: string;
  description: string;
}

interface Iendpoint extends Iendpoint_small {
  usergroups: string[];
}

// ----------------------------------------
export default function Nutzerverwaltung() {
  const { call_backend_api } = React.useContext(APIWrapperContext);
  const [newUsergroupProcess, setNewUsergroupProcess] = React.useState(false);
  const [newUsergroupName,setNewUsergroupName]=React.useState("")
  const [secondary, setSecondary] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [endpointList, setEndpointList] = React.useState<Iendpoint[]>([]);
  const [endpointList_Left, setEndpointList_Left] = React.useState<
    Iendpoint_small[]
  >([]);
  const [endpointList_Right, setEndpointList_Right] = React.useState<
    Iendpoint_small[]
  >([]);
  const [selectedClass, setSelectedClass] = React.useState<string | undefined>(
    undefined
  );
  const [newUsergroup, setNewUsergroup] = React.useState<string | undefined>(
    undefined
  );
  const [openEndpointSuccessChanger, setOpenEndpointSuccessChanger] =
    React.useState(false);

  const [usergroups, setUserGroups] = React.useState<Iusergroup[]>([]);
  const [addUsergroupDisabled, setAddUsergroupDisabled] = React.useState(true);

  function delay(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  const updateEndpointList = () => {
    call_backend_api(
      SecurityService.provideEndpointListSecurityEndpointListGet(),
      (t: Iendpoint[]) => setEndpointList(t),
      undefined,
      undefined,
      false
    );
  };

  const handleUpdateEndpointsOfUsergroups = (
    UsergroupEndpoints: string[] = [],
    saveActionName: undefined | string = undefined
  ) => {
    if (saveActionName && selectedClass) {
      let newEndpoints = UsergroupEndpoints.filter(
        (endpoint) =>
          !endpointList_Right.map((el) => el.end_func).includes(endpoint)
      );
      let removeEndpoints = endpointList_Right.filter(
        (endpoint) => !UsergroupEndpoints.includes(endpoint.end_func)
      );
      newEndpoints.forEach((endpoint) => {
        addUsergroupToEndpoint(selectedClass, endpoint);
        call_backend_api(
          SecurityService.addUsergroupToEndpointSecurityAddUsergroupPost(
            endpoint,
            selectedClass
          ),
          undefined,
          undefined,
          undefined,
          true,
          "Erfolgreich hinzugefügt",
          "Hinzufügen fehlgeschlagen"
        );
      });
      removeEndpoints.forEach((endpoint) => {
        removeUsergroupFromEndpoint(selectedClass, endpoint);
        call_backend_api(
          SecurityService.removeUsergroupToEndpointSecurityRemoveUsergroupDelete(
            endpoint.end_func,
            selectedClass
          ),
          undefined,
          undefined,
          undefined,
          true,
          "Erfolgreich entfernt",
          "Entfernen fehlgeschlagen"
        );
      });
    }
    setOpenEndpointSuccessChanger(false);
    if (saveActionName) {
      delay(1000).then(
        () => updateEndpointList()
        // getEndpointList("").then((t) => setEndpointList(t))
      );
    }
  };
  const updateUsergroups =()=>{
    call_backend_api(
      UserService.listUserGroupsUserGroupsGet(),
      (t: any) => setUserGroups(t),
      undefined,
      undefined,
      false
    );
  }
  React.useEffect(() => {
    updateUsergroups()
    // getUserGroups(true).then((t) => {
    //   console.log("userg", t);
    //   setUserGroups(t);
    // });
  }, []);
  React.useEffect(() => {
   
  }, [usergroups]);

  React.useEffect(() => {
    updateEndpointList();
  }, []);
  React.useEffect(() => {
    
    if (selectedClass) {
      let tmpLeft: Iendpoint_small[] = endpointList
        .filter((el) => !el.usergroups.includes(selectedClass))
        .map((el) => {
          return { end_func: el.end_func, description: el.description };
        });
      let tmpRight: Iendpoint_small[] = endpointList
        .filter((el) => el.usergroups.includes(selectedClass))
        .map((el) => {
          return { end_func: el.end_func, description: el.description };
        });
      console.log("tmpLists", tmpLeft, tmpRight);
      setEndpointList_Left(tmpLeft);
      setEndpointList_Right(tmpRight);
    }
  }, [endpointList, selectedClass]);

  React.useEffect(() => {
    if (newUsergroup && newUsergroup.length > 0) {
      setAddUsergroupDisabled(false);
    } else {
      setAddUsergroupDisabled(true);
    }
  }, [newUsergroup]);
  const handleAddNewUsergroup = (groupname:string) => {
    call_backend_api(
      UserService.addNewUsergroupUserAddNewUsergroupPost(groupname),()=>{updateUsergroups()},undefined,undefined, true,"Usergruppe erfolgreich hinzugefügt","Usergruppe konnte nicht hinzugefügt werden"
    )
    setNewUsergroupName("")
    setNewUsergroupProcess(false)
  };

  return (
    <>
      <Container component="main" sx={{ width: "100%" }}>
        <Typography sx={{ width: "100%" }} variant="h4">
          Nutzergruppen
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {/* <h4>Nutzergruppen verwalten</h4>
          <Box sx={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <TextField
              sx={{ m: 1, flex: 1 }}
              required
              id="outlined-required"
              label="Name der neuen Nutzergruppe"
              defaultValue=""
              value={newUsergroup}
              onChange={(e) => {
                setNewUsergroup(String(e.target.value));
              }}
            />
            <IconButton
              size="large"
              color="primary"
              disabled={addUsergroupDisabled}
              onClick={handleAddNewUsergroup}
            >
              <AddCircleOutline></AddCircleOutline>
            </IconButton>
          </Box>

          <Divider variant="middle"></Divider> */}
          <h4>Endpoint-Verwaltung für Nutzergruppen</h4>
          {usergroups && (
            <>
              <UsergroupSelect
                classnames={usergroups.map((ug) => ug.classname)}
                setSelectedClass={setSelectedClass}
              />
            </>
          )}
          {selectedClass && (
            <>
              <h5>Freigegebene Endpoints ({endpointList_Right.length})</h5>
              {/* <p>{endpointList_Right.map((el) => el.end_func).join(", ")}</p> */}
              <Box>
                {endpointList_Right
                  .sort((a, b) => Number(a.end_func >= b.end_func))
                  .map((el) => {
                    return (
                      <Chip
                        sx={{ m: 0.2 }}
                        label={el.end_func}
                        icon={
                          <>
                            {el.description == "" ? undefined : (
                              <Tooltip
                                title={
                                  el.description == ""
                                    ? "Keine Beschreibung hinterlegt"
                                    : el.description
                                }
                                placement={"right"}
                              >
                                <InfoOutlined />
                              </Tooltip>
                            )}
                          </>
                        }
                      />
                    );
                  })}
              </Box>
              <Button
                onClick={() => setOpenEndpointSuccessChanger(true)}
                variant="outlined"
              >
                Ändern
              </Button>
            </>
          )}

          {
            //     (selectedClass) &&
            // <TransferList leftList={endpointList_Left } rightList={endpointList_Right} setLeftList={setEndpointList_Left} setRightList={setEndpointList_Right} titleLeft={"unzulässige Endpoints"} titleRight={"erreichbare Endpoints"}></TransferList>
          }
          {
            selectedClass && (
              <ChipTransferListEndpointSelection
                title="Freigegebene Endpoints"
                saveActionName={"endpointsSave"}
                groupList={endpointList_Left}
                usersGroups={endpointList_Right}
                titleLeft="Offene Endpoints"
                titleRight="Gesperrte Endpoints"
                open={openEndpointSuccessChanger}
                closeFunction={handleUpdateEndpointsOfUsergroups}
              ></ChipTransferListEndpointSelection>
            )
            // <TransferList leftList={endpointList_Left } rightList={endpointList_Right} setLeftList={setEndpointList_Left} setRightList={setEndpointList_Right} titleLeft={"unzulässige Endpoints"} titleRight={"erreichbare Endpoints"}></TransferList>
          }
          {/* <h4>Endpoint Beschreibung</h4>
          {endpointList.map(el=>{
            return (
                <>
                <Box>
                    <b>{el.end_func}:</b> {el.description}
                </Box>
                </>
            )
          })} */}
          <Box>
            {/* <IconButton> */}
            <h4>Nutzergruppen hinzufügen</h4>
            {!newUsergroupProcess && (
              <Button
                onClick={() => {
                  setNewUsergroupProcess(true);
                }}
              >
                <Add></Add>
                Neue Nutzergruppe
              </Button>
            )}
            {newUsergroupProcess && (
              <>
                <Box sx={{ width: "100%" }}>
                  <TextField 
                    onChange={(e)=>{setNewUsergroupName(e.target.value)}}
                    id="newUsergroupName"
                    sx={{ width: "100%" }}
                  ></TextField>
                  <Box
                    sx={{
                      width: "100%",
                      float: "right",
                      display: "flex",
                      flexDirection: "row",
                      mt:1
                    }}
                  >
                    <Box sx={{ flex: 1 }}></Box>
                    <Button variant="outlined" onClick={()=>{setNewUsergroupProcess(false)}}>
                      <Close></Close> Abbrechen
                    </Button>
                    <Button variant="contained" sx={{ ml: 1 }} onClick={()=>{handleAddNewUsergroup(newUsergroupName)}}>
                      <Save></Save> Speichern
                    </Button>
                  </Box>
                </Box>
              </>
            )}
            {/* </IconButton> */}
          </Box>
        </Box>
      </Container>
    </>
  );
}
