import { Box, CircularProgress, CircularProgressProps, Container, Typography } from "@mui/material"
import React, { useEffect } from "react";
import { call_backend_api } from "../../../../services/APIWrapper";
import { HidriveService } from "../../../../services/openapi";

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex',// borderRadius:'100%',backgroundColor:'text.secondary'
      }}>
        <CircularProgress size={80} variant="determinate" {...props} color="primary"/>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
          fontSize={20}
            variant="caption"
            component="div"
            color="text.primary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
interface ISpaceWithUnit{
size: number
unit: string
}

interface IAvailableSpace{
maxCapacity:ISpaceWithUnit,
usedCapacity:ISpaceWithUnit,
freeCapacity:ISpaceWithUnit,
percentage:ISpaceWithUnit
}
export const SpeicherUebersicht = () => {


    const [percentage, setPercentage] = React.useState(100);
    const [availableSpace, setAvailableSpace] = React.useState<IAvailableSpace>({} as IAvailableSpace)
    
    useEffect(() => {
      call_backend_api(HidriveService.getHidriveAvailableSpaceHidriveGetAvailableSpaceGet(),(res:IAvailableSpace)=>{
        setPercentage(res.percentage.size)
        setAvailableSpace(res)
      })
    },[])

    React.useEffect(() => {
        // Todo: Connect to backend
        const interval = setInterval(() => {
            //setPercentage((prevPercentage) => (prevPercentage >= 100 ? 0 : prevPercentage + 10));
        }, 800);
        return () => clearInterval(interval);
    }, []);
    return (
        <>
      <Container component="main" >
        <Typography variant="h4">Speicherübersicht</Typography>
        {/* <CssBaseline /> */}

        <Box sx={{ width: '100%' ,mt:2}}>
            <CircularProgressWithLabel value={percentage} />

        </Box>
        <Box>
          <Typography>Gesamter Speicherplatz: {availableSpace.maxCapacity?availableSpace.maxCapacity.size && availableSpace.maxCapacity.unit?availableSpace.maxCapacity.size+" "+availableSpace.maxCapacity.unit:"":""}</Typography>
          <Typography>Verwendeter Speicherplatz: {availableSpace.usedCapacity?availableSpace.usedCapacity.size && availableSpace.usedCapacity.unit?availableSpace.usedCapacity.size.toFixed(2)+" "+availableSpace.usedCapacity.unit:"":""}</Typography>
          <Typography>Freier Speicherplatz: {availableSpace.freeCapacity?availableSpace.freeCapacity.size && availableSpace.freeCapacity.unit?availableSpace.freeCapacity.size.toFixed(2)+" "+availableSpace.freeCapacity.unit:"":""}</Typography>
        </Box>
        </Container>
        </>
    )
}