import List from "@mui/material/List";
import {
  Avatar,
  Dialog,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { Person } from "@mui/icons-material";

interface IDialogChooseChangeUsersOrUsergroupsProps {
  onClose: Function;
  open: boolean;
}

export function DialogChooseChangeUsersOrUsergroups({
  onClose,
  open,
}: IDialogChooseChangeUsersOrUsergroupsProps) {
  const options = [
    "Freigegeben Nutzergruppen verwalten",
    "Freigegeben Nutzer verwalten",
  ];

  const handleClose = () => {
    onClose(undefined);
  };

  const handleListItemClick = (value: any) => {
    onClose(value);
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Dokumenten Zugriff Verwalten</DialogTitle>
      <List sx={{ pt: 0 }}>
        {options.map((op) => (
          <ListItem onClick={() => handleListItemClick(op)} key={op}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <Person />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={op} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
