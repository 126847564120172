import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import IndicatorWorktime from "./IndicatorWorktime";
import APIWrapperContext from "../../../../../services/APIWrapperContext";
import { ArbeitszeitService, OpenAPI } from "../../../../../services/openapi";
import {
  addDaysToDate,
  convertDateToISODateString,
  getIsoWeekOfDate,
} from "../timehelperFunctions";
import { IWorktime } from "./interfaces";
import dayjs from "dayjs";

import { DatePicker } from "@mui/x-date-pickers";
import ZeiterfassungDashboardContext from "../../../../../Contexts/ZeiterfassungDashboardContext/ZeiterfassungDashboardContext";
import WorkingDataOverviewTable from "./WorkingDataOverviewTable";
import { Settings, Update } from "@mui/icons-material";
import ZeiterfassungDashboardNoteContextProvider from "../../../../../Contexts/ZeiterfassungDashboardContext/ZeiterfassungDashboardNoteContextProvider";
import WorkingDataSetBearbeiter from "./WorkingDataSetBearbeiter";

export default function WorkingDataOverview() {
  const { call_backend_api } = useContext(APIWrapperContext);
  const {
    selectedDateStart,
    setSelectedDateStart,
    selectedDateEnde,
    setSelectedDateEnde,
    arbeitszeiten_alle_MA,
    handle_Upadate_Arbeitszeiten_alle_MA,
  } = useContext(ZeiterfassungDashboardContext);
  const [open, setOpen] = useState(false);
  useEffect(() => {}, []);

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="h3">Übersicht - erfasste Zeiten</Typography>
        <Box sx={{ flex: 1 }} />
        <Box sx={{ display: "flex", flexDirection: "column", mr: 1 }}>
          <Box sx={{ flex: 1 }} />
          <IconButton onClick={()=>{setOpen(true)}}>
            
            <Settings />
          </IconButton>
          <Box sx={{ flex: 1 }} />
        </Box>
        <WorkingDataSetBearbeiter open={open} setOpen={setOpen} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <DatePicker
            value={dayjs(selectedDateStart)}
            onChange={(value) => {
              if (value != null) {
                setSelectedDateStart(value.toDate());
              }
            }}
            sx={{ m: 1, ml: 0 }}
          ></DatePicker>
          <DatePicker
            value={dayjs(selectedDateEnde)}
            onChange={(value) => {
              if (value != null) {
                setSelectedDateEnde(value.toDate());
              }
            }}
            sx={{ m: 1 }}
          ></DatePicker>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flex: 1 }} />
            <IconButton onClick={handle_Upadate_Arbeitszeiten_alle_MA}>
              <Update />
            </IconButton>
            <Box sx={{ flex: 1 }} />
          </Box>
          <Box sx={{ flex: 1 }} />
        </Box>
        <ZeiterfassungDashboardNoteContextProvider>
          <WorkingDataOverviewTable
            timeRange={{ start: selectedDateStart, end: selectedDateEnde }}
            notes={
              arbeitszeiten_alle_MA.notes ? arbeitszeiten_alle_MA.notes : []
            }
            user_zeiterfassung={
              arbeitszeiten_alle_MA.user_zeiterfassung
                ? arbeitszeiten_alle_MA.user_zeiterfassung
                : []
            }
            data={arbeitszeiten_alle_MA.arbeitszeiten?.map((el: any) => {
              let el_tmp = JSON.parse(JSON.stringify(el));
              el_tmp.user_id = arbeitszeiten_alle_MA.user_zeiterfassung.find(
                (x: any) => x.user_id === el_tmp.user_id
              )?.user_id;
              return el_tmp;
            })}
          />
        </ZeiterfassungDashboardNoteContextProvider>
      </Box>
    </>
    // </ThemeProvider>
  );
}
