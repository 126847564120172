import { Avatar, AvatarGroup, IconButton } from "@mui/material";
import { Idir_Users_Userclasses } from "../FileList_Components";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import React from "react";
import { DeleteOutline } from "@mui/icons-material";

export const fileActionsDeleteFolder = (handleDeleteFolder: Function) => {
  return function test(dir: Idir_Users_Userclasses) {
     function sub_test() {
      

      
      return (
        <>
          
          
          
          {
            (dir!==undefined && dir.type==="dir" )&&
            <IconButton
            color="secondary"
            edge="end"
            aria-label="download"
            onClick={(e) =>{ 
              e.stopPropagation()
              handleDeleteFolder(dir)
            }}
            >
            <DeleteOutline />
          </IconButton>
          }
        </>
      );
    };
    return React.createElement(sub_test);
  };
};
