import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';


import * as serviceWorker from '../serviceWorkerRegistration';
import CloseIcon from '@mui/icons-material/Close';


import { Button, IconButton, Snackbar } from '@mui/material';



interface IServiceWorkerUIProps {
    children?: React.ReactNode;
}

export default function ServiceWorkerUI(props:IServiceWorkerUIProps) {
  const [waitingWorker, set_waitingWorker] = useState<ServiceWorker>()
  const [newVersionAvailable, set_newVersionAvailable] = useState(false)
  const [openSnackbar, set_openSnackbar] = useState(false)
  const [deferredPrompt, set_deferredPrompt] = useState<any>()
  const [showInstallPromotion, set_showInstallPromotion] = useState<boolean>()
  
  
  const handleShowInstallation = useCallback((e:any) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    set_deferredPrompt(e);
    // Update UI notify the user they can install the PWA
    set_showInstallPromotion(true);
  }, []);
  
  const installationButtonHit = async () => {
    // Hide the app provided install promotion
    set_showInstallPromotion(false);
    // Show the install prompt

    if(deferredPrompt && deferredPrompt.prompt) deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.userChoice;
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    set_deferredPrompt(null);
  }
  
  const handleAppInstalled = useCallback((e:any) => {
    set_showInstallPromotion(false);
    // Clear the deferredPrompt so it can be garbage collected
    set_deferredPrompt(null);
    // Optionally, send analytics event to indicate successful install
    console.log('PWA was installed');
  }, []);
  
  
  
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleShowInstallation);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleShowInstallation);
    };
  }, [handleShowInstallation]);
  useEffect(() => {
    window.addEventListener('appinstalled', handleAppInstalled);
    return () => {
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, [handleAppInstalled]);
  
  
  const onServiceWorkerUpdate = (registration:ServiceWorkerRegistration) => {
    console.log("waitingWorkers?")
    console.log(registration && registration.waiting)
    if(registration && registration.waiting!==null)    set_waitingWorker(registration.waiting)
    set_newVersionAvailable(true)
  
  
  }
  
  const updateServiceWorker = (e: React.MouseEvent<HTMLElement>) => {
    console.log("update Executed")
    // const { waitingWorker } = this.state
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' })
    set_newVersionAvailable(false)
    set_openSnackbar(false)
    console.log("window would relode")
    window.location.reload()
  }
  
  
  
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
    }
  }, [])
  useEffect(() => {
    console.log("newVersionAvailable changed")
    if (newVersionAvailable) {
      console.log("openSnackbar")
      set_openSnackbar(true)
  
    } //show snackbar with refresh button
  }, [newVersionAvailable])

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      {showInstallPromotion && <>
        <Button onClick={() => { installationButtonHit() }}>Install as App</Button>
      </>}
      {props.children}
      <Snackbar
        key={"updateInfo"}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => { set_openSnackbar(false) }}
        TransitionProps={{ onExited: () => { set_openSnackbar(false) } }}
        message={"Es ist eine neue Version verfügbar"}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={updateServiceWorker}>
              Update
            </Button>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => { set_openSnackbar(false) }}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
    // </ThemeProvider>
  );
}