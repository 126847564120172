import * as React from 'react';
import { OpenHouses } from "./types";
import { IAppartment, IHouse } from "./interfaces";
import { Menu, MenuItem } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { Divider, IconButton, ListItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { getUsers } from '../../../../BackendFunctions/backend_api_UserService';
import { addHouse, getAppartment, addAppartment, updateAppartment, updateHouse, getHouses, deleteHouse, deleteAppartment } from '../../../../BackendFunctions/backend_api_HausverwaltungService';


import DialogRawNewHouse from './DialogWindowNewHouse';
import DisAgreeDialogRaw from './DialogWindowDisAgree';
import DialogRawNewAppartment from './DialogWindowNewAppartment';
import DialogRawEditAppartment from './DialogWindowEditAppartment';
import { Interface } from 'readline';
import APIWrapperContext from '../../../../services/APIWrapperContext';
import { HausverwaltungService } from '../../../../services/openapi';
import { UserService } from '../../../../services/openapi';
import SpeedDialOptions from './SpeedDialOptions';
const actions = [
    { icon: <ApartmentIcon />, name: 'Haus hinzufügen' },
    { icon: <MeetingRoomIcon />, name: 'Wohnung / Zimmer hinzufügen' },
    // { icon: <PrintIcon />, name: 'Print' },
    // { icon: <ShareIcon />, name: 'Share' },
];


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
        border: "1px solid black"
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

interface IpropsHausUndAppartmentListe {
    hauslist: Array<IHouse>,
    handleMobileMenuOpen: (event: React.MouseEvent<HTMLButtonElement>, house: IHouse) => void,
    handleClick: (house: string) => void,
    open:OpenHouses,
    handleMobileMenuOpen_Appartment: (event: React.MouseEvent<HTMLButtonElement>, appartment: IAppartment) => void,
}



export default function HausUndAppartmentListe(props:IpropsHausUndAppartmentListe) {
    const {call_backend_api} = React.useContext(APIWrapperContext)
    const {hauslist,handleMobileMenuOpen,handleClick,open,handleMobileMenuOpen_Appartment} = props


    

    return (
        <>
            {/* <p>{JSON.stringify(hauslist)}</p> */}

            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                // subheader={
                //     <ListSubheader component="div" id="nested-list-subheader">
                //         Nested List Items
                //     </ListSubheader>
                // }
            >
                

                {hauslist.map(haus => {

                    return (
                        <>
                            <ListItem
                            >
                                {/* <ListItemButton onClick={()=>handleClick(haus.name)}> */}
                                <ListItemIcon>
                                    <ApartmentIcon />
                                </ListItemIcon>
                                <ListItemText primary={haus.name} />
                                <IconButton color="primary" edge="end" aria-label="download" onClick={(e) => { console.log(JSON.stringify(haus)); handleMobileMenuOpen(e, haus)}}>
                                    <MoreVertIcon />
                                </IconButton>
                                <IconButton color="secondary" edge="end" aria-label="download" onClick={() => handleClick(haus.name)}>
                                    {open[haus.name] ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                                {/* </ListItemButton> */}
                            </ListItem>
                            <Collapse in={open[haus.name]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {haus.appartments?haus.appartments.map(appart => {
                                        return (
                                            <ListItem sx={{ pl: 12, width: "100%" }}>
                                                <ListItemIcon>
                                                    { <MeetingRoomIcon /> }
                                                </ListItemIcon>
                                                <ListItemText primary={appart.number} />
                                                <IconButton color="primary" edge="end" aria-label="download" onClick={(e) =>{let temp_app = JSON.parse(JSON.stringify(appart));temp_app.house=haus.name; handleMobileMenuOpen_Appartment(e, temp_app)}}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </ListItem>
                                        )
                                    }):[]}
                                </List>
                            </Collapse>
                            <Divider />
                        </>
                    )
                })
                }
            </List >
            
            
            
            
           
        </>
    );
}
