import * as React from 'react';
import {OpenHouses} from "./types";
import {IMenuItem, IpropsMobileMenu} from "./interfaces";
import { Menu, MenuItem } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { Divider, IconButton, ListItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { getUsers } from '../../../../BackendFunctions/backend_api_UserService';
import { addHouse, getAppartment, addAppartment, updateAppartment, updateHouse, getHouses, deleteHouse, deleteAppartment } from '../../../../BackendFunctions/backend_api_HausverwaltungService';


import DialogRawNewHouse from './DialogWindowNewHouse';
import DisAgreeDialogRaw from './DialogWindowDisAgree';
import DialogRawNewAppartment from './DialogWindowNewAppartment';
import DialogRawEditAppartment from './DialogWindowEditAppartment';
import { Interface } from 'readline';
import APIWrapperContext from '../../../../services/APIWrapperContext';
import { HausverwaltungService } from '../../../../services/openapi';
import { UserService } from '../../../../services/openapi';
import SpeedDialOptions from './SpeedDialOptions';
import HausUndAppartmentListe from './HausUndAppartmentListe';
const actions = [
    { icon: <ApartmentIcon />, name: 'Haus hinzufügen' },
    { icon: <MeetingRoomIcon />, name: 'Wohnung / Zimmer hinzufügen' },
    // { icon: <PrintIcon />, name: 'Print' },
    // { icon: <ShareIcon />, name: 'Share' },
];


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
        border: "1px solid black"
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));



export default function MobileMenu(props:IpropsMobileMenu) {
    const {call_backend_api} = React.useContext(APIWrapperContext)
    const {mobileMoreAnchorEl,open,handleClose,menuItems} = props;
   
    

    const mobileMenuId = 'menu-mobile';
    


    return (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
        >
            {menuItems.map((item:IMenuItem) => (
                <MenuItem onClick={item.onClickFunc}>
                    <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                        {/* <Badge badgeContent={10} color="secondary"> */}
                        {item.icon}
                        {/* </Badge> */}
                    </IconButton>
                    <p>{item.name}</p>
                </MenuItem>
            ))}
            
            
        </Menu >
    );
}
