import React, { useCallback, useContext, useState } from "react";
// import { makeStyles } from '@material-ui/core/styles';
// import { Button, Grid, Typography } from '@material-ui/core';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  Box,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { Button, Grid, Typography } from "@mui/material";
import Dropzone, { FileWithPath, useDropzone } from "react-dropzone";
import { uploadFile } from "../../../../BackendFunctions/backend_api_HausverwaltungService";
import { call_backend_api } from "../../../../services/APIWrapper";
import { HausverwaltungService } from "../../../../services/openapi";
import { IFileUploaderProps } from "../../Interfaces/interfaces";
import APIWrapperContext from "../../../../services/APIWrapperContext";



function FileUploader(props:IFileUploaderProps) {
  const {
    onClose,
    
    open,
    title,
    dir_id,
   
    ...other
  } = props;
  const {call_backend_api} = useContext(APIWrapperContext)
  const radioGroupRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (!open) {
      
    }
  }, [ open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    setMyFiles([])
    onClose();
  };

  const [myFiles, setMyFiles] = useState<FileWithPath[]>([]);
  const handleOk = () => {
    myFiles.forEach((f:FileWithPath) =>
      call_backend_api(
        HausverwaltungService.uploadFileHausverwaltungAddFileToHausverwaltungPost(
          { dir_id: dir_id, file: f }
        ),undefined,undefined,undefined,true
      )
    );
    setMyFiles([])
    onClose();
  };

  const theme = useTheme();

  const onDrop = useCallback(
    (acceptedFiles:FileWithPath[]) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const removeFile = (file:FileWithPath) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
  };
  const files = myFiles.map((file) => (
    <Chip
      sx={{ m: 0.2 }}
      label={file.path + " - " + (file.size/1024/1024).toFixed(2) + " MB"}
      //   deleteIcon={<AddCircleOutlineIcon />}
      onDelete={removeFile(file)}
    />
    //                 <li key={file.path}>
    //   {file.path} - {file.size} bytes
    // </li>
  ));
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <section className="container">
            <Box
              sx={{ border: "2px dotted " + theme.palette.primary.dark, p: 1 }}
              {...getRootProps({ className: "dropzone" })}
            >
              <input {...getInputProps()} />
              <p>
                Dateien hier per Drag 'n' drop hochladen, oder Dateien per Click
                selektieren
              </p>
            </Box>
            <Box>
              <h4>Dateien zum hochladen</h4>
              {files}
            </Box>
          </section>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleOk} autoFocus>
          Hochladen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default FileUploader;
