import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import ZeiterfassungContext from "../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext";
import APIWrapperContext from "../../../../services/APIWrapperContext";
import { ArbeitszeitService } from "../../../../services/openapi";

export default function ZeiterfassungNeuesBauvorhaben() {
  const { call_backend_api } = useContext(APIWrapperContext);
  
  const [Mandant, setMandant] = React.useState<string>("");
  const [ProjektnummerText, setProjektnummerText] = React.useState<string>("");
  const [Projektnummer, setProjektnummer] = React.useState<number>(-1);
  const [nameBauvorhaben, setNameBauvorhaben] = React.useState<string>("");
  const { bauvorhaben,createNewBauvorhaben } = React.useContext(ZeiterfassungContext);
  function containsOnlyDigits(str: string) {
    return /^\d+$/.test(str);
  }

  const evalErrorMandant = (mandant: string) => {};

  const evalErrorProjektnummer = (projektnummer: string) => {
    if (projektnummer.length > 0) {
      return containsOnlyDigits(projektnummer);
    }
    return false;
  };

  const evalReadyForCreate = () => {
    if (
      Mandant.length > 0 &&
      Projektnummer > 0 &&
      nameBauvorhaben.length > 0 &&
      evalErrorProjektnummer(ProjektnummerText)
    ) {
      return true;
    }
    return false;
  };

  const handleMandant = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMandant(event.target.value);
  };
  const handleProjektnummer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjektnummerText(event.target.value);
    if (evalErrorProjektnummer(event.target.value)) {
      setProjektnummer(parseInt(event.target.value));
    }
  };
  const handleNameBauvorhaben = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNameBauvorhaben(event.target.value);
  };

  const handleCreateNeuesBauvorhaben = () => {
    createNewBauvorhaben(parseInt(Mandant),Projektnummer,nameBauvorhaben);
    
  };

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      {bauvorhaben && bauvorhaben === "Neu" && (
        <Paper
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            mt: -1,
          }}
        >
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <TextField
              sx={{ m: 1 }}
              id="outlined-basic"
              label="Mandant"
              variant="outlined"
              onChange={handleMandant}
            />
            <TextField
              sx={{ m: 1 }}
              id="filled-basic"
              label="Projektnummer"
              variant="outlined"
              onChange={handleProjektnummer}
              error={!evalErrorProjektnummer(ProjektnummerText)}
              helperText={
                !evalErrorProjektnummer(ProjektnummerText)
                  ? "Projektnummer muss eine Zahl sein."
                  : ""
              }
            />
            <TextField
              sx={{ m: 1 }}
              id="standard-basic"
              label="Name Bauvorhaben"
              variant="outlined"
              onChange={handleNameBauvorhaben}
            />
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color="primary"
              disabled={!evalReadyForCreate()}
              onClick={handleCreateNeuesBauvorhaben}
            >
              Erstellen
            </Button>
          </Box>
        </Paper>
      )}
    </>
    // </ThemeProvider>
  );
}
