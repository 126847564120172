import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { NotizArbeitstagUser } from '../../services/openapi/models/NotizArbeitstagUser';

export interface NotizArbeitstagUser_id extends NotizArbeitstagUser{
  id:number|undefined
}



interface ZeiterfassungDashboardNoteContextInterface {
    selectedDate:Date,
                setSelectedDate:(date:Date)=>void,
                notiz:string,
                setNotiz:(notiz:string)=>void,
                id:number|undefined,
                setId:(id:number)=>void,
                user_id:string,
                setUser_id:(user_id:string)=>void,
                addNote:()=>void,
                preSetNote:(note:NotizArbeitstagUser_id)=>void,
    
    
}


const defaultContext: ZeiterfassungDashboardNoteContextInterface = {
    selectedDate: new Date(new Date().setHours(0,0,0,0)),
                setSelectedDate: () => {},
                notiz: "",
                setNotiz: () => {},
                id: -1,
                setId: () => {},
                user_id: "",
                setUser_id: () => {},
                addNote: () => {},
                preSetNote: () => {},
    
};

const ZeiterfassungDashboardNoteContext = React.createContext<ZeiterfassungDashboardNoteContextInterface>(defaultContext);

export default ZeiterfassungDashboardNoteContext;
