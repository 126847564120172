import {
  Check,
  CheckCircle,
  CheckCircleOutline,
  ErrorOutline,
} from "@mui/icons-material";
import { Box, Button, Icon, Paper, TextField, Typography,CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import APIWrapperContext from "../../../../services/APIWrapperContext";
import {
  Arbeitszeiteintrag,
  ArbeitszeitService,
  ReturnStempeln,
  Stempeleintrag,
} from "../../../../services/openapi";
import { convertSecondsToHourMinuteString } from "./timehelperFunctions";

interface IStatus {
  statusText: "Eingestempelt" | "Ausgestempelt" | "Fehler" | string;
  color: "red" | "green";
  statusCode: "successEnter" | "successExit" | "error";
  icon: React.ReactNode;
}

interface IAdditionaInfo {
  startTime?: Date;
  endTime?: Date;
  bvh?: string;
  date: Date;
  dauer?: number;
}

export default function ZeiterfassungStempeln() {
  let params = useParams();
  const history = useNavigate();
  const initialized = useRef(false);
  const { call_backend_api } = useContext(APIWrapperContext);
  const [bvhID, setBvhID] = React.useState<string>(params.bvhID as string);
  const [status, setStatus] = React.useState<IStatus>();
  const [additionaInfo, setAdditionaInfo] = React.useState<IAdditionaInfo>();

  const statusEinstempeln: IStatus = {
    statusText: "Eingestempelt",
    color: "green",
    statusCode: "successEnter",
    icon: <CheckCircleOutline sx={{ fontSize: 60 }} />,
  };
  const statusAusstempeln: IStatus = {
    statusText: "Ausgestempelt",
    color: "green",
    statusCode: "successExit",
    icon: <CheckCircleOutline sx={{ fontSize: 60 }} />,
  };
  const statusFehler: IStatus = {
    statusText: "Fehler",
    color: "red",
    statusCode: "error",
    icon: <ErrorOutline sx={{ fontSize: 60 }} />,
  };

  const statusFehlerSpecialText = (text: string) => {
    let output: IStatus = {
      statusText: "Fehler: " + text,
      color: "red",
      statusCode: "error",
      icon: <ErrorOutline sx={{ fontSize: 60 }} />,
    };
    return output;
  };

  useEffect(() => {
    history("/zeiterfassung/stempeln/bauvorhaben",{replace:true});
    //history.push("/zeiterfassung/stempeln/");
    if (!initialized.current) {
      initialized.current = true;
     
      call_backend_api(
        ArbeitszeitService.execStempelnArbeitzeitStempeleintragPost(bvhID),
        (res: ReturnStempeln) => {
          if (res.status === "einstempeln") {
            setStatus(statusEinstempeln);
            setAdditionaInfo({
              startTime: new Date(res.eintrag.beginn+"Z"),
              bvh: (res.eintrag as any).bvh.bvh_name,
              date: new Date(res.eintrag.datum),
            });
          } else {
            setStatus(statusAusstempeln);
            setAdditionaInfo({
              startTime: new Date(res.eintrag.beginn+"Z"),
              endTime: new Date((res.eintrag as Arbeitszeiteintrag).ende+"Z"),
              //bvh: (res.eintrag as any).bvh.bvh_name,
              date: new Date(res.eintrag.datum),
              dauer: (res.eintrag as Arbeitszeiteintrag).dauer,
            });
          }

         
        },
        (res: any) => {
          setStatus(statusFehlerSpecialText(res.body.detail));
        },
        undefined,
        false
      );
    }
  }, []);

  return (
    <>
      <Box sx={{flex:1}}></Box>
      <Box
        sx={{
            display: "flex",
            flexDirection: "column",
            align: "center",
            width: "100%",
        }}
      >
        {status === undefined && <>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <Box sx={{ flex: 1 }}></Box>
            <CircularProgress color="primary" />
              <Box sx={{ flex: 1 }}></Box>
              </Box>
        </>}
        {status !== undefined && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              align: "center",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <Box sx={{ flex: 1 }}></Box>
              <Icon sx={{ color: status.color, fontSize: 60 }}>
                {/* <CheckCircleOutline sx={{ fontSize: 60 }}/> */}
                {status.icon}
              </Icon>
              <Box sx={{ flex: 1 }}></Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <Box sx={{ flex: 1 }}></Box>
              <Typography variant="h6">{status.statusText}</Typography>
              <Box sx={{ flex: 1 }}></Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <Box sx={{ flex: 1 }}></Box>
              <Box sx={{ display:'flex', flexDirection:'column' }}>
              {status.statusCode === "successEnter" && (
                <>
                  <Typography variant="h6">
                    Baustelle: {additionaInfo?.bvh}
                    
                  </Typography>
                  <Typography variant="h6">
                    Beginn Arbeitzeit: {additionaInfo?.startTime?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} Uhr
                    
                  </Typography>
                  <Typography variant="h6">
                    Datum: {additionaInfo?.date?.toLocaleDateString([], { day: "2-digit", month: "2-digit", year: "numeric" })}

                  </Typography>
                </>
              )}
              {status.statusCode === "successExit" && (
                <>
                  <Typography variant="h6">
                    Arbeitszeit: {convertSecondsToHourMinuteString(additionaInfo && additionaInfo.dauer?additionaInfo.dauer:0)} 
                  </Typography>
                  <Typography variant="h6">
                    Beginn: {additionaInfo?.startTime?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} Uhr
                  </Typography>
                  <Typography variant="h6">
                    Ende: {additionaInfo?.endTime?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} Uhr
                  </Typography>
                </>
              )}
              </Box>
              <Box sx={{ flex: 1 }}></Box>
            </Box>
          </Box>
        )}
      </Box>
<Box sx={{flex:1}}></Box>
    </>
  );
}
