import { Avatar, AvatarGroup, IconButton } from "@mui/material";
import { Idir_Users_Userclasses } from "../FileList_Components";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";
import { CreateNewFolder } from "@mui/icons-material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
export const fileActionsHausverwaltung = (handleClick_EditSharedUsers: Function,handleUpload:Function,handleAddNewFolder:Function=()=>{},handleDeleteFile:Function=()=>{}) => {
  return function test(dir: Idir_Users_Userclasses) {
     function sub_test() {
      function stringToColor(string: string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = "#";

        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
      }

      function stringAvatar(name: string) {
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: !name.includes(" ")
            ? `${name[0]}`
            : `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
        };
      }
      return (
        <>
          

          {/*------------------------*/}
          {dir !== undefined &&
            dir.sharedUsergroups !== undefined &&
            dir.sharedUsergroups.length !== 0 && (
              <>
                <AvatarGroup
                  total={dir.sharedUsergroups.length}
                  sx={{ marginRight: "10px" }}
                >
                  {dir.sharedUsergroups.map((su) => {
                    return <Avatar {...stringAvatar(su)} title={su} />;
                  })}
                  {/* <Avatar {...stringAvatar('Kent Dodds')} />
                            <Avatar {...stringAvatar('Jed Watson')} />
                            <Avatar {...stringAvatar('Tim Neutkens')} />
                            <Avatar {...stringAvatar('Tim Neutkens')} /> */}
                </AvatarGroup>
              </>
            )}

          {dir !== undefined &&
            dir.sharedUsers !== undefined &&
            dir.sharedUsers.length !== 0 && (
              <AvatarGroup
                total={dir.sharedUsers.length}
                sx={{ marginRight: "10px" }}
              >
                {dir.sharedUsers.map((su) => {
                  return <Avatar {...stringAvatar(su)} />;
                })}
                {/* <Avatar {...stringAvatar('Kent Dodds')} />
                            <Avatar {...stringAvatar('Jed Watson')} />
                            <Avatar {...stringAvatar('Tim Neutkens')} />
                            <Avatar {...stringAvatar('Tim Neutkens')} /> */}
              </AvatarGroup>
            )}
          <IconButton
            color="secondary"
            edge="end"
            aria-label="download"
            onClick={() => handleClick_EditSharedUsers(dir)}
          >
            
              <GroupAddIcon />
            
          </IconButton>
          {dir.type != "dir" ? <IconButton
            sx={{ ml: 0.5,  }}
            color="secondary"
            edge="end"
            aria-label="download"
            onClick={() => handleDeleteFile(dir)}
          >
            <DeleteForeverIcon /> 
          </IconButton>: <></>}
          {dir.type == "dir" ? <IconButton
            sx={{ ml: 0.5,  }}
            color="secondary"
            edge="end"
            aria-label="download"
            onClick={() => handleUpload(dir)}
          >
            <CloudUploadIcon /> 
          </IconButton>: <></>}
          {dir.type == "dir" ?<IconButton
            sx={{ ml: 0.5, mr: 0.5 }}
            color="secondary"
            edge="end"
            aria-label="download"
            onClick={() => handleAddNewFolder(dir)}
          >
             <CreateNewFolder /> 
          </IconButton>: <></>}
        </>
      );
    };
    return React.createElement(sub_test);
  };
};
