import React, { useCallback, useEffect } from 'react';

import UserCreationContext from './UserCreationContext';

interface IUserCreationContextProviderProps {
    children?: React.ReactNode;
}
interface Ihaus {
    house: string;
    number: string;
    appartments: Ihaus[];
  }

const UserCreationContextProvider = ({ children }:IUserCreationContextProviderProps) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats

   
    const [code, setCode] = React.useState<string>("");
    const [name, setName] = React.useState<string>("");
    const [kundennummer, setKundennummer] = React.useState<string>("");
    const [isOwner, setIsOwner] = React.useState<boolean>(false);
    const[accessibleAppartments, setAccessibleAppartments] = React.useState<Ihaus[]>([]);
    const[userGroups, setUserGroups] = React.useState<string[]>([]);
    const[Ansprechpartner, setAnsprachpartner] = React.useState<string>("");
    const[Mail_Ansprechpartner, setMail_Ansprachpartner] = React.useState<string>("");


    const resetUserPreConf = () => {
        setCode("");
        setName("");
        setKundennummer("");
        setIsOwner(false);
        setAccessibleAppartments([]);
        setUserGroups([]);
        setAnsprachpartner("");
        setMail_Ansprachpartner("");


    }
    

    const buildEnvelope = useCallback(() => {
        return {
            name: name,
            kundenummer: kundennummer,
            isOwner: isOwner,
            usergroups: userGroups,
            appartments: accessibleAppartments.map((el) => {return {house: el.house, number: el.number}}),
            Ansprechpartner: Ansprechpartner,
            Mail_Anprechpartner: Mail_Ansprechpartner

    
        }
    },[name, kundennummer, isOwner, userGroups, accessibleAppartments,Ansprechpartner,Mail_Ansprechpartner]) 

return (
    <UserCreationContext.Provider
        value={{
            code,
            setCode,
            name,
            setName,
            kundennummer,
            setKundennummer,
            isOwner,
            setIsOwner,
            accessibleAppartments,
            setAccessibleAppartments,

userGroups,
setUserGroups,
Ansprechpartner,
setAnsprachpartner,
Mail_Ansprechpartner,
setMail_Ansprachpartner,
resetUserPreConf,
buildEnvelope


        }}
    >
    <>
        {children}
    </>
    </UserCreationContext.Provider>
);
};

export default UserCreationContextProvider;
