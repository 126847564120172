/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_add_new_ad_ads_newAd_post } from '../models/Body_add_new_ad_ads_newAd_post';
import type { Body_updateAds_ads_updateAd_put } from '../models/Body_updateAds_ads_updateAd_put';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdsService {

    /**
     * Add new Advertisement Endpoint
     * @param link 
     * @param imgUrl 
     * @param description 
     * @param location 
     * @param status 
     * @param visible 
     * @param formData 
     * @param id 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addNewAdAdsNewAdPost(
link: string,
imgUrl: string,
description: string,
location: string,
status: string,
visible: boolean,
formData: Body_add_new_ad_ads_newAd_post,
id?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ads/newAd',
            query: {
                'id': id,
                'link': link,
                'img_url': imgUrl,
                'description': description,
                'location': location,
                'status': status,
                'visible': visible,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Advertisement Endpoint
     * @param link 
     * @param imgUrl 
     * @param description 
     * @param location 
     * @param status 
     * @param visible 
     * @param id 
     * @param formData 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateAdsAdsUpdateAdPut(
link: string,
imgUrl: string,
description: string,
location: string,
status: string,
visible: boolean,
id?: number,
formData?: Body_updateAds_ads_updateAd_put,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ads/updateAd',
            query: {
                'id': id,
                'link': link,
                'img_url': imgUrl,
                'description': description,
                'location': location,
                'status': status,
                'visible': visible,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List of all Ads
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAllAdsAdsAdsListGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ads/adsList',
        });
    }

    /**
     * List of all visible Ads
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAllAdsVisibleAdsAdsListVisibleGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ads/adsListVisible',
        });
    }

    /**
     * Returns Images for Advertisements
     * @param imageId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getImagesOfAdsAdsImageGet(
imageId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ads/image',
            query: {
                'image_id': imageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
