import { IconButton } from "@mui/material";
import Update from "@mui/icons-material/Update";


interface IUpdateButtonProps {
  updateFunction?:Function

}

export default function UpdateButton({updateFunction=()=>{}}: IUpdateButtonProps) {
  return (
    <>
      <IconButton
          onClick={() => {
            updateFunction();
          }}
        >
          <Update
            onClick={() => {
              updateFunction();
            }}
          ></Update>
        </IconButton>
      
    </>
  );
}
