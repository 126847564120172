/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdditionalUserData } from '../models/AdditionalUserData';
import type { NewRegistrationMailForUser } from '../models/NewRegistrationMailForUser';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Generates a new Registration Code - Admins only
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getNewRegistrationCodeAuthRegistrationGenerateCodeGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/registration/generate_code',
        });
    }

    /**
     * Zusatzinfos zum Registration Code hinzufügen - Admins only
     * @param code 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addInfoToRegistrationCodeAuthRegistrationGenerateCodeAdditionalInfosPost(
code: string,
requestBody: AdditionalUserData,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/registration/generate_code/additionalInfos',
            query: {
                'code': code,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Registration Mail - Admins only - out of order at the moment
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendRegistrationMailAuthRegistrationSendRegistrationMailPost(
requestBody: NewRegistrationMailForUser,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/registration/sendRegistrationMail',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
