import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Chip, Paper } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface IChipTransferListProps {
  title: string;
  groupList: string[];
  usersGroups: string[];
  titleLeft: string;
  titleRight: string;
  open: boolean;
  closeFunction: (
    newValue?: string[] | undefined,
    action?: string | undefined
  ) => void;
  saveActionName?: string;
}

export default function ChipTransferList({
  title,
  groupList = [],
  usersGroups = [],
  titleLeft = "",
  titleRight = "",
  open = false,
  closeFunction = (newValue, action) => {},
  saveActionName = undefined,
}: IChipTransferListProps) {
  const [groupList_state, setGrouplist_state] = React.useState(groupList);
  const [Usergroups_state, setUsergroups_state] = React.useState(usersGroups);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  const handleSave = () => {
    closeFunction(Usergroups_state, saveActionName);
  };
  const handleAbort = () => {
    closeFunction(undefined, undefined);
  };
  React.useEffect(() => {
  
  }, [groupList_state]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  React.useEffect(() => {
    setUsergroups_state(usersGroups);
    setGrouplist_state(groupList);
  }, [groupList, usersGroups]);

  const addUsergroup = (groupName: string) => {
    let groupList_tmp = [...groupList_state];
    groupList_tmp = groupList_tmp.filter((g) => g !== groupName);
    let usersGroups_tmp = [...Usergroups_state];
    usersGroups_tmp.push(groupName);
    setGrouplist_state(groupList_tmp);
    setUsergroups_state(usersGroups_tmp);
  };
  const removeUsergroup = (groupName: string) => {
    let usersGroups_tmp = [...Usergroups_state];
    usersGroups_tmp = usersGroups_tmp.filter((g) => g !== groupName);
    let groupList_tmp = [...groupList_state];
    groupList_tmp.push(groupName);
    setUsergroups_state(usersGroups_tmp);
    setGrouplist_state(groupList_tmp);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: '80%' } }}
      maxWidth="md"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <h4>{titleLeft}</h4>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            p: 0.5,
            m: 0,
          }}
          component="ul"
        >
          {Usergroups_state.map((g) => {
            return (
              <ListItem key={g}>
                <Chip
                  label={g}
                  onDelete={() => {
                    
                    removeUsergroup(g);
                  }}
                />
              </ListItem>
            );
          })}
        </Paper>
        <h4>{titleRight}</h4>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            p: 0.5,
            m: 0,
          }}
          component="ul"
        >
          {groupList_state.map((g) => {
            return (
              <ListItem key={g}>
                <Chip
                  label={g}
                  
                  deleteIcon={<AddCircleOutlineIcon />}
                  onDelete={() => {
                    addUsergroup(g);
                  }}
                />
              </ListItem>
            );
          })}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleSave} variant="contained">
          Speichern
        </Button>
        <Button onClick={handleAbort} variant="outlined">
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
