import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box } from '@mui/material';
import ZeiterfassungContext from '../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext';

interface IZeiterfassungSelectBauvorhabenProps {
    
}

export default function ZeiterfassungSelectBauvorhaben(props: IZeiterfassungSelectBauvorhabenProps) {
    const {  } = props;
    const {bauvorhaben, setBauvorhaben,bauvorhabenList} = React.useContext(ZeiterfassungContext);
  
    
  const handleChange = (event: SelectChangeEvent) => {
    setBauvorhaben(event.target.value);
  };
  

  
  return (
    <Box sx={{ display: 'flex', flexDirection:'column',width:'100%', mt:2,mb:1 }}>
      
      <FormControl required sx={{  minWidth: 120, width:"100%" }}>
        <InputLabel id="bauvorhaben_id">Bauvorhaben</InputLabel>
        <Select
          labelId="bauvorhaben_id"
          id="bauvorhaben_id-required"
          value={bauvorhaben}
          label="Bauvorhaben *"
          onChange={handleChange}
        >
          <MenuItem value="Neu">
            <em>Neu</em>
          </MenuItem>
          {
                bauvorhabenList.map((bauvorhaben) => {
                    return <MenuItem value={bauvorhaben.bvh_name}>{bauvorhaben.bvh_name}</MenuItem>
                })
          }
          
        </Select>
        {/* <FormHelperText>Required</FormHelperText> */}
      </FormControl>
    </Box>
  );
}