import React from 'react';
import { AdditionalUserData } from '../../../../services/openapi/models/AdditionalUserData';
interface Ihaus {
    house: string;
    number: string;
    appartments: Ihaus[];
  }
interface IUserCreationContext{
    code: string;
    setCode: (code: string) => void;
    
    name: string;
    setName: (name: string) => void;

    kundennummer: string;
    setKundennummer: (kundennummer: string) => void;

    isOwner: boolean;
    setIsOwner: (isOwner: boolean) => void;
    
    accessibleAppartments: Ihaus[];
    setAccessibleAppartments: (accessibleAppartments: Ihaus[]) => void;

    userGroups: string[];
    setUserGroups: (userGroups: string[]) => void;

    Ansprechpartner: string;
    setAnsprachpartner: (Ansprechpartner: string) => void;

    Mail_Ansprechpartner: string;
    setMail_Ansprachpartner: (Ansprechpartner: string) => void;

    resetUserPreConf: () => void;
    buildEnvelope: () => AdditionalUserData;
}


const defaultContext:IUserCreationContext = {
    code: "",
    setCode: () => {
        // Initial value. Replaced by context provider.
        
    },
    name: "",
    setName: () => {
        // Initial value. Replaced by context provider.
        
    },
    kundennummer: "",
    setKundennummer: () => {
        // Initial value. Replaced by context provider.
        
    },
    isOwner: false,
    setIsOwner: () => {
        // Initial value. Replaced by context provider.
        
    },
    accessibleAppartments: [],
    setAccessibleAppartments: () => {
        // Initial value. Replaced by context provider.
        
    },
    userGroups: [],
    setUserGroups: () => {
        // Initial value. Replaced by context provider.
        
    },
    Ansprechpartner: "",
    setAnsprachpartner: () => {},
    Mail_Ansprechpartner: "",
    setMail_Ansprachpartner: () => {},
    
    resetUserPreConf: () => {
        // Initial value. Replaced by context provider.
    },
    buildEnvelope: () => {
        return {
            name: "",
            kundenummer: "",
            Ansprechpartner:"",
            Mail_Anprechpartner:"",
            isOwner: false,
            usergroups: [],
            appartments: []
        }
        // Initial value. Replaced by context provider.
    }
};

const UserCreationContext = React.createContext(defaultContext);

export default UserCreationContext;
