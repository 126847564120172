import * as React from "react";
import { Box, Button, CssBaseline, TextField } from "@mui/material";
import { styled, alpha, useTheme } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import APIWrapperContext from "../../../../services/APIWrapperContext";
import { PrivacyService } from "../../../../services/openapi";

export default function Privacy() {
  const { call_backend_api } = React.useContext(APIWrapperContext);

  let theme = useTheme();
  const [text, setText] = React.useState("");
  const [tmptext, setTmpText] = React.useState("");
  const [changeDate, setChangeDate] = React.useState<Date | undefined>(
    undefined
  );
  //const text = "Ihre Daten werden manuell überprüft und abgeglichen um zu gewährleisten, dass auch nur  Eigentümer von Wohnanlagen in diesem Portal angemeldet sind. Bei Eigentümerwechsel  werden die Daten des ausscheidenden Eigentümers automatisch gelöscht.  Auf Registrierung von Dritten wird nicht reagiert. Wenn Sie sich registriert haben und von  uns keine Rückmeldung erhalten haben, so bitten wir Sie sich bei uns zu melden  (info@böhringer.net).  Wir nehmen den Schutz Ihrer Daten sehr ernst, daher geben wir Ihre persönlichen Daten  einschließlich Ihrer Anschrift und E-Mail-Adresse nicht an Dritte weiter.  Darüber hinaus können Sie auf unserer Internetseite nachlesen wie wir mit dem Thema  Datenschutz umgehen."

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTmpText(event.target.value);
  }

  const loadText = () => {
    call_backend_api(
        PrivacyService.readPrivacyPolicyPrivacyPrivacyPolicyGet(),
        (res: any) => {
          setText(res.text);
          setTmpText(res.text);
          setChangeDate(new Date(res.date));
        },
        () => {
          setText("Fehler beim Laden der Datenschutzerklärung");
        },
        undefined,
        false
      );
  }

  const onSave = () => {
    call_backend_api(
        PrivacyService.changePrivacyPolicyPrivacyChangePrivacyPolicyPost(tmptext),
        (res: any) => {
          
          
        },
        () => {
          
        },
        ()=>{loadText()},
        true,
        "Datenschutzerklärung geändert",
        "Fehler beim Ändern der Datenschutzerklärung"
      );
  }


  React.useEffect(() => {
    loadText()
    //setText
  }, []);

  return (
    <>
      <Container component="main" sx={{ height: "100%" }}>
        <Typography
          sx={{ typography: { xs: "h4", md: "h3" }, wordBreak: "break-all" }}
        >
          Datenschutzerklärung bearbeiten
        </Typography>
        {/* <CssBaseline /> */}

        <Box
          sx={{
            // width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            mt:5
          }}
        >
          <Typography variant="h5">
            Aktuelle Datenschutzerklärung: (Letzte Änderung:{" "}
            {changeDate?.toLocaleDateString() + " " + changeDate?.toLocaleTimeString([],{ hour: "2-digit", minute: "2-digit" })})
          </Typography>
          <Box dangerouslySetInnerHTML={{__html: text}} />
          {/* {text.split("<br>").map((t, i) => (
            <Typography key={i}>{t}</Typography>
          ))} */}
          <Box sx={{ flex: 1,mt: 5 }}>
            <Typography >Bearbeiten: ({"Für Zeilenumbrüche <br> einfügen"})</Typography>
            <TextField
              sx={{ width: "100%",   }}
              placeholder="Datenschutzerklärung"
              //   value={text}
              defaultValue={text}
              multiline
            //   rows={20}
              maxRows={20}
              onChange={onChangeText}
            />
          <Button variant="contained" sx={{mt:2}} onClick={()=>{onSave()}}>Speichern</Button>
          </Box>

        </Box>
      </Container>
    </>
  );
}
