import * as React from "react";
import { OpenAPI } from "../services/openapi";


export const downloadFile = (setDownloading: any | React.Dispatch<React.SetStateAction<boolean>>) => {
  return (id: string, filename: string,) => {
    let url = "" + OpenAPI.BASE + "/doc/getFile/?id=" + id;
    //url = "" + "http://localhost:8008" + "/doc/getFile/?id=" + id
    console.log(url, url);

    setDownloading(true);

    fetch(url, {
      method: "GET",
      // mode: 'cors', // no-cors, *cors, same-origin
      // headers: {

      //     'Access-Control-Allow-Origin': '*'
      // }
      mode: "cors",
      headers: {
        Accept: "application/json",
        //     'Authorization': 'Bearer ',
        //     //   'content-type': 'application/json'
      },
    })
      // (B) RETURN AS BLOB
      .then((result) => {
        console.log(result);
        if (result.status != 200) {
          throw new Error("Bad server response");
        }
        //console.log(result.blob())
        return result.blob();
      })

      // (C) BLOB DATA
      .then((data: Blob | null) => {
        // (C1) FILE DATA IS "READY FOR USE"
        //console.log(data);

        // (C2) TO "FORCE DOWNLOAD"
        if (data !== null) {
          var url = URL.createObjectURL(data),
            anchor = document.createElement("a");
          anchor.href = url;
          let saveas = filename ? filename : "Datei.pdf";
          anchor.download = saveas;
          document.body.append(anchor);
          anchor.click();

          // (C3) CLEAN UP
          URL.revokeObjectURL(url);
          document.body.removeChild(anchor);
        }
        data = null

        setDownloading(false);
      })

      // (D) HANDLE ERRORS - OPTIONAL
      .catch((error) => {
        console.log(error);


        setDownloading(false);
      });
  };
};

export const downloadFolder = (setDownloading: any | React.Dispatch<React.SetStateAction<boolean>>) => {

  return (id: string, filename: string,) => {
    console.log("downloadFolder", id, filename);
    let url = "" + OpenAPI.BASE + "/doc/Files/download?id=" + id;
    //url = "" + "http://localhost:8008" + "/doc/getFile/?id=" + id
    console.log(url, url);

    setDownloading(true);

    fetch(url, {
      method: "GET",
      // mode: 'cors', // no-cors, *cors, same-origin
      // headers: {

      //     'Access-Control-Allow-Origin': '*'
      // }
      mode: "cors",
      headers: {
        Accept: "application/json",
        //     'Authorization': 'Bearer ',
        //     //   'content-type': 'application/json'
      },
    })
      // (B) RETURN AS BLOB
      .then((result) => {
        console.log(result);
        if (result.status != 200) {
          throw new Error("Bad server response");
        }
        //console.log(result.blob())
        return result.blob();
      })

      // (C) BLOB DATA
      .then((data) => {
        // (C1) FILE DATA IS "READY FOR USE"
        //console.log(data);

        // (C2) TO "FORCE DOWNLOAD"
        var url = URL.createObjectURL(data),
          anchor = document.createElement("a");
        anchor.href = url;
        let saveas = filename ? (filename + ".zip") : "Ordner.zip";
        anchor.download = saveas;
        document.body.append(anchor);
        anchor.click();

        // (C3) CLEAN UP
        URL.revokeObjectURL(url);
        document.body.removeChild(anchor);

        setDownloading(false);
      })

      // (D) HANDLE ERRORS - OPTIONAL
      .catch((error) => {
        console.log(error);


        setDownloading(false);
      });
  };
};

