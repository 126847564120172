import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import { Outlet, Route, Routes } from "react-router-dom";
import Dokumentenverwaltung from "./SubPages/Dokumentenverwaltung/Dokumentenverwaltung";
import Faqs from "./SubPages/FAQ/FAQ";
import Hausverwaltung from "./SubPages/Hausverwaltung/Hausverwaltung";
import FileListe from "../../List/FileList";
import Nutzerverwaltung from "./SubPages/Nutzerverwaltung/Nutzerverwaltung";
import UserOptions from "./SubPages/UserOptions/UserOptions";

import Ads from "../Widgets/Advertisement/Ads";
import AppMain from "../AppBar/AppMain";
import Nutzergruppenverwaltung from "./SubPages/Nutzergruppenverwaltung/Nutzergruppenverwaltung";

import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import * as reactRouterDom from "react-router-dom";
import { SpeicherUebersicht } from "./SubPages/SpeicherUebersicht/SpeicherUebersicht";
import { ConfigureAds } from "../Widgets/Advertisement/ConfigureAds";
import Privacy from "./SubPages/Privacy/Privacy";
import PrivacyEdit from "./SubPages/Privacy/PrivacyEdit";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";
import UserDetails from "./SubPages/UserDetails";
import { Logs } from "./SubPages/Logging/Logging";
import DeleteFolder from "./SubPages/Ordnerloeschen/DeleteFolder";
import Zeiterfassung from "./SubPages/Zeiterfassung/Zeiterfassung";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ZeiterfassungStempeln from "./SubPages/Zeiterfassung/ZeiterfassungStempeln";
import Dashboard from "./SubPages/Zeiterfassung/Dashboard/Dashboard";
import 'dayjs/locale/de'
interface IMainPagesProps {}
//

export default function MainPages(props: IMainPagesProps) {
  const theme = useTheme();
  //const { setOpenDrawer } = React.useContext(AppBarContext)

  const drawerWidth = 340;

  const DrawerHeader = styled("div")(({ theme }) => ({
    // display: 'flex',
    alignItems: "center",
    padding: theme.spacing(0, 1),
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">

      
      <AppMain>
        <DrawerHeader theme={theme} />
        <Routes key="TestThis">
          {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
            EmailPasswordPreBuiltUI,
            EmailVerificationPreBuiltUI,
          ])}

          {/* <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/restorepassword" element={<RestorePassword />} /> */}
          <Route
            path="/terms-of-service"
            element={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  maxWidth: "lg",
                  ml: "auto",
                  mr: "auto",
                }}
              >
                <Faqs />
              </Box>
            }
          />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route
            path="/privacy-policy-edit"
            element={
              <SessionAuth>
                <PrivacyEdit />
              </SessionAuth>
            }
          />
          <Route
            path="/faqs"
            element={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  maxWidth: "lg",
                  ml: "auto",
                  mr: "auto",
                }}
              >
                <Faqs />
              </Box>
            }
          />
          <Route
            path="/"
            element={
              <SessionAuth>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    width:'100%',
                    maxWidth: "lg",
                    ml: "auto",
                    mr: "auto",
                  }}
                >
                  <Box sx={{ minHeight: 200, flex: 1 }}>
                    <UserDetails />
                    <FileListe />
                  </Box>
                  <Box
                    sx={{
                      minHeight: 200,
                      display: "flex",
                      flexDirection: { xs: "column", md: "column" },
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" sx={{ mb: 3, mt: 3 }}>
                      Aktuell bauen wir für Sie:
                      {/* Aktuelle Projekte im Bau */}
                    </Typography>
                    <Ads></Ads>
                  </Box>
                </Box>
              </SessionAuth>
              // <Startseite />
            }
          />

          <Route
            path="/usergroupmanagement"
            element={
              <SessionAuth>
                <Nutzergruppenverwaltung />
              </SessionAuth>
            }
          />
          <Route
            key={"TestRouteUsermanagement"}
            path="/usermanagement"
            element={
              <SessionAuth>
                <Nutzerverwaltung />
              </SessionAuth>
            }
          />
          <Route
            path="/document_management"
            element={
              <SessionAuth>
                <Dokumentenverwaltung />
              </SessionAuth>
            }
          />
          <Route
            path="/hausverwaltung"
            element={
              <SessionAuth>
                <Hausverwaltung />
              </SessionAuth>
            }
          />
          <Route
            path="/cloud_storage"
            element={
              <SessionAuth>
                <SpeicherUebersicht />
              </SessionAuth>
            }
          />
          <Route
            path="/configure_ads"
            element={
              <SessionAuth>
                <ConfigureAds />
              </SessionAuth>
            }
          />
          <Route
            path="/logs"
            element={
              <SessionAuth>
                <Logs />
              </SessionAuth>
            }
          />
          {/* <Route path="/documents" element={<FileListe />} /> */}
          <Route
            path="/user_options"
            element={
              <SessionAuth>
                <UserOptions />
              </SessionAuth>
            }
          />
          <Route
            path="/deleteFolder"
            element={
              <SessionAuth>
                <DeleteFolder />
              </SessionAuth>
            }
          />
          <Route
            path="/zeiterfassung/dashboard"
            element={
              <SessionAuth>
                <Dashboard/>
              </SessionAuth>
            }
          />
          <Route
            path="/zeiterfassung"
            element={
              <SessionAuth>
                <Zeiterfassung/>
              </SessionAuth>
            }
          />
          <Route
            path="/zeiterfassung/stempeln/:bvhID"
            element={
              <SessionAuth>
                <ZeiterfassungStempeln/>
              </SessionAuth>
            }
          />
        </Routes>
      </AppMain>
      </LocalizationProvider>
    </>
    // </ThemeProvider>
  );
}
