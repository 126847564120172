/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SecurityService {

    /**
     * Get a list of all available Endpoints
     * @returns any Successful Response
     * @throws ApiError
     */
    public static provideEndpointListSecurityEndpointListGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security/endpointList/',
        });
    }

    /**
     * Enable usergroup to access endpoint
     * @param endpointName 
     * @param usergroupName 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addUsergroupToEndpointSecurityAddUsergroupPost(
endpointName: string,
usergroupName: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/security/addUsergroup/',
            query: {
                'endpoint_name': endpointName,
                'usergroup_name': usergroupName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Disable usergroup to access endpoint
     * @param endpointName 
     * @param usergroupName 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeUsergroupToEndpointSecurityRemoveUsergroupDelete(
endpointName: string,
usergroupName: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/security/removeUsergroup/',
            query: {
                'endpoint_name': endpointName,
                'usergroup_name': usergroupName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
