import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import IndicatorWorktime from "./IndicatorWorktime";
import APIWrapperContext from "../../../../../services/APIWrapperContext";
import { ArbeitszeitService, OpenAPI } from "../../../../../services/openapi";
import {
  addDaysToDate,
  convertDateToISODateString,
  getIsoWeekOfDate,
} from "../timehelperFunctions";
import { IWorktime } from "./interfaces";
import dayjs from "dayjs";
import LinkGeneratorForNFC from "./LinkGeneratorForNFC";
import { DatePicker } from "@mui/x-date-pickers";
import ZeiterfassungDashboardContext from "../../../../../Contexts/ZeiterfassungDashboardContext/ZeiterfassungDashboardContext";

export default function ExportWorkingData() {
  const { call_backend_api } = useContext(APIWrapperContext);
  const { selectedDateStart,setSelectedDateStart,selectedDateEnde,setSelectedDateEnde} = useContext(ZeiterfassungDashboardContext)
  const transformDate_toString = (date:Date) => {
    
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let date_string = date.getFullYear() + "-" + month + "-" + day;
    return date_string;
  }
  const  exportData = async () => {

    let url_test = "" + OpenAPI.BASE + "/arbeitzeit/dashboard/export/worktimes?timeInterval_start="+transformDate_toString(selectedDateStart)+"&timeInterval_end="+transformDate_toString(selectedDateEnde);
    const response = await fetch(url_test, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'export.xlsx'; // Name noch editieren
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    // call_backend_api(
      
      
    //     ArbeitszeitService.getWorktimesOfAllUsersAsExportArbeitzeitDashboardExportWorktimesPost(
    //     convertDateToISODateString(new Date()),
    //     convertDateToISODateString(
    //       new Date(new Date().getTime() - 9 * 24 * 60 * 60 * 1000)
    //     )
    //   ),
    //   async (res: any) => {
    //     // setWorktimes(res);
    //     console.log(res)
        
    //   },
    //   undefined,
    //   undefined,
    //   false
    // );
  };

  useEffect(() => {}, []);
  

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      
        <Box sx={{ display: "flex", flexDirection: "row",m:2 }}>
      <Typography variant="h5">Export</Typography>
          {/* <DatePicker value={dayjs(selectedDateStart)} onChange={(value)=>{if (value!=null){setSelectedDateStart(value.toDate())}}} sx={{m:1}}></DatePicker>
          <DatePicker value={dayjs(selectedDateEnde)} onChange={(value)=>{if (value!=null){setSelectedDateEnde(value.toDate())}}} sx={{m:1}}></DatePicker> */}
            
        <Box sx={{ flex:1 }}/>
        
        

        <Button
          onClick={() => {
            exportData();
          }}
          variant="contained"
        >
          Export
        </Button>
      </Box>
      
    </>
    // </ThemeProvider>
  );
}
