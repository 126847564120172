import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface IFormDialog_NewHouseProps {
    open:boolean,
    handleClose: (values?:FormData) => void
}
interface IFormDialog_EditHouseProps extends IFormDialog_NewHouseProps {
    currentAdresse:string
    newHouse?:boolean 
}

export function FormDialogNewHouse(props: IFormDialog_NewHouseProps) {
  
const {open,handleClose} = props;


  return (
    <React.Fragment>
      
      <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
        open={open}
        onClose={()=>{handleClose()}}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            handleClose(new FormData(event.currentTarget));
            // const formData = new FormData(event.currentTarget);
            // const formJson = Object.fromEntries((formData as any).entries());
            // const email = formJson.email;
            // console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Neues Haus hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* To subscribe to this website, please enter your email address here. We
            will send updates occasionally. */}
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="adresse"
            name="adresse"
            label="Adresse - Ort, Straße, Hausnummer"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleClose()}}>Abbrechen</Button>
          <Button type="submit">Hinzufügen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export function FormDialogEditHouse(props: IFormDialog_EditHouseProps) {
  
const {open,handleClose, currentAdresse,newHouse} = props;


  return (
    <React.Fragment>
      
      <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
        open={open}
        onClose={()=>{handleClose()}}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            handleClose(new FormData(event.currentTarget));
            // const formData = new FormData(event.currentTarget);
            // const formJson = Object.fromEntries((formData as any).entries());
            // const email = formJson.email;
            // console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>{newHouse===true?"Neues Haus":"Haus bearbeiten"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* To subscribe to this website, please enter your email address here. We
            will send updates occasionally. */}
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="adresse"
            name="adresse"
            label="Adresse - Ort, Straße, Hausnummer"
            defaultValue={currentAdresse}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleClose()}}>Abbrechen</Button>
          <Button type="submit">{newHouse===true?"Speichern":"Ändern"}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}