import * as React from "react";
import { Box, Checkbox, Chip, FormControlLabel, InputAdornment, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getRegistrationCodeForNewUser } from "../../../../BackendFunctions/backend_api_AuthService";
import { sendMailWithRegistrationCode } from "../../../../BackendFunctions/backend_api_AuthService";
import NutzerListe from "./UserList";
import { ipaddress } from "../../../../BackendFunctions/ipconfig";
import { getHouses } from "../../../../BackendFunctions/backend_api_HausverwaltungService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { preConfigUser } from "../../../../BackendFunctions/backend_api_UserService";

import {
  AuthService,
  HausverwaltungService,
  UserService,
} from "../../../../services/openapi";
import { SyntheticEvent } from "react";
import APIWrapperContext from "../../../../services/APIWrapperContext";
import { WidgetEmailEditor } from "../../../Widgets/EmailEditor/WidgetEmailEditor";
import ConfigureNewUser from "./ConfigureNewUser";
import UserCreationContext from "./UserCreationContext";
import { FilterAlt } from "@mui/icons-material";

interface Ihaus {
  house: string;
  number: string;
  appartments: Ihaus[];
}

export default function AddUsergroupsToNewUser() {
  // const { token, sessionStart } = React.useContext(SecurityContext)
  const {
    name,
    setName,
    kundennummer,
    setKundennummer,
    isOwner,
    setIsOwner,
    userGroups,
    setUserGroups,
  } = React.useContext(UserCreationContext);
  const [filter, setFilter] = React.useState<string>("");
  const handle_ChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };
    const addUsergroup_PreConfUserAccess = (usergroup: string) => {
        let groupList_tmp = [...userGroups];
    
        groupList_tmp.push(usergroup);
        //set_hauslist_preConfUserAccess(groupList_tmp);
        setUserGroups(groupList_tmp);
    };
    const removeUsergroup_PreConfUserAccess = (usergroup: string) => {
        let usersGroups_tmp = [...userGroups];
        usersGroups_tmp = usersGroups_tmp.filter(
            (g) => !(g === usergroup)
        );
        setUserGroups(usersGroups_tmp);
    };
  const [userGroups_available, setUserGroups_available] = React.useState<
    string[]
  >([]);
  const { call_backend_api } = React.useContext(APIWrapperContext);
  React.useEffect(() => {
    call_backend_api(
      UserService.listUserGroupsUserGroupsGet(),
      (t: any[]) => setUserGroups_available(t.map((el) => el.classname)),
      undefined,
      undefined,
      false,
      undefined,
      undefined
    );
  }, []);

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <Paper elevation={3} sx={{ flex: 1, p: 1 }}>
          <Typography sx={{ textDecoration: "underline" }}>
            Zugewiesene Benutzergruppen:
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {userGroups.map((ug) => (
              <>
              <Box sx={{ display:'flex',flexDirection:'row' }} >

                <Chip
                  sx={{ m: 0.2 }}
                  label={ug}
                  onDelete={() => {
                      removeUsergroup_PreConfUserAccess(ug);
                    }}
                    />
                    <Box sx={{ flex:1 }} />
                    </Box>
              </>
            ))}
            {/* {hauslist_preConfUserAccess.map((el) => (
                <Chip
                  sx={{ m: 0.2 }}
                  label={"Haus:" + el.house + " - Wohnung:" + el.number}
                  onDelete={() => {
                    removeAppartment_PreConfUserAccess(el);
                  }}
                />
              ))} */}
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ flex: 1, ml: 2, p: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ flex: 1 }}></Box>
              <Typography sx={{ textDecoration: "underline" }}>
                Verfügbare Nutzergruppen:
              </Typography>
              <Box sx={{ flex: 1 }} />
            </Box>
            <Box sx={{ flex: 1 }} />
            <TextField
              onChange={handle_ChangeFilter}
              id="outlined-start-adornment"
              size="small"
              sx={{}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterAlt />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
            {userGroups_available

              .filter((ug_available) => !(userGroups.includes(ug_available)) && ug_available.includes(filter))

              .map((ug_available) => (
                <>
                <Box sx={{ display:'flex',flexDirection:'row' }} >

                  <Chip
                    sx={{ m: 0.2 }}
                    label={ug_available}
                    deleteIcon={<AddCircleOutlineIcon />}
                    onDelete={() => {
                        addUsergroup_PreConfUserAccess(ug_available);
                    }}
                    />
                  <Box sx={{ flex:1 }} />
                    </Box>
                </>
              ))}

            {/* {hauslist
              .reduce((p: Ihaus[], c) => {
                c.appartments.forEach((el: Ihaus) => p.push(el));
                return p;
              }, [])
              .filter((el) =>
                hauslist_preConfUserAccess.reduce(
                  (p, c) =>
                    p == false
                      ? p
                      : !(el.house == c.house && el.number == c.number),
                  true
                )
              )
              .map((el) => (
                <Chip
                  sx={{ m: 0.2 }}
                  label={"Haus:" + el.house + " - Wohnung:" + el.number}
                  deleteIcon={<AddCircleOutlineIcon />}
                  onDelete={() => {
                    addAppartment_PreConfUserAccess(el);
                  }}
                />
              ))} */}
            {/* {JSON.stringify(hauslist)} */}
          </Box>
        </Paper>
      
      </Box>
    </>
  );
}
