import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { TimeField, TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import ZeiterfassungContext from "../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext";

interface IZeiterfassungSelectArbeitszeiten {}

let options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

export default function ZeiterfassungSelectArbeitszeiten(
  props: IZeiterfassungSelectArbeitszeiten
) {
  const {} = props;

  

  

  
  const {startZeit,setStartZeit,endeZeit,setEndeZeit,pauseZeit,setPauseZeit} = React.useContext(ZeiterfassungContext)

  

  return (
    // <Box sx={{ display: 'flex', flexDirection:'column',width:'100%',mt:2  }}>
    <>
    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", mt: 2 }}>
      <TimeField
        sx={{ ml: 1 }}
        label="von"
        // defaultValue={dayjs('2022-04-17T07:00')}
        format="HH:mm"
        value={startZeit}
        onChange={(date) => {
          setStartZeit(date);
        }}
        color={
          startZeit && endeZeit && startZeit.isAfter(endeZeit)
            ? "error"
            : "primary"
        }
        helperText={
          startZeit && endeZeit && startZeit.isAfter(endeZeit)
            ? "Start muss vor Ende sein"
            : ""
        }
        FormHelperTextProps={{ sx: { color: "red" } }}
        referenceDate={dayjs("1900-01-01T07:00")}
      />
      <TimeField
        sx={{ ml: 1 }}
        label="bis"
        value={endeZeit}
        onChange={(date) => {
          setEndeZeit(date);
        }}
        color={
          startZeit && endeZeit && startZeit.isAfter(endeZeit)
          ? "error"
            : "primary"
        }
        helperText={
          startZeit && endeZeit && startZeit.isAfter(endeZeit)
            ? "Ende muss nach Start sein"
            : ""
        }
        FormHelperTextProps={{ sx: { color: "red" } }}
        referenceDate={dayjs("1900-01-01T07:00")}
        // defaultValue={dayjs('2022-04-17T17:00')}
        format="HH:mm"
      />
      <TimeField
        sx={{ ml: 1, mr: 1 }}
        label="Pause"
        onChange={(date) => {
          setPauseZeit(date);
        }}
        value={pauseZeit}
        referenceDate={dayjs("1900-01-01T01:00")}
        color={
          pauseZeit &&
          endeZeit?.diff(startZeit) &&
          endeZeit?.diff(startZeit) -
            pauseZeit.diff(dayjs("1900-01-01T00:00")) <
            0
            ? "error"
            : "primary"
          }
          helperText={
            (pauseZeit &&
              endeZeit?.diff(startZeit) &&
              endeZeit?.diff(startZeit) -
              pauseZeit.diff(dayjs("1900-01-01T00:00")) <
              0) ||
              endeZeit?.diff(startZeit) == 0
              ? "Pause kann nicht länger als Arbeitszeit sein."
              : ""
        }
        FormHelperTextProps={{ sx: { color: "red" } }}
        format="HH:mm"
      />
      
    </Box>
      {(pauseZeit?.isValid() )&& endeZeit?.isValid() && pauseZeit.isValid() &&(
        <>
        <Box sx={{m:2}}>
        
        <Typography variant="h6">
           
          {"Arbeitszeit: "+(dayjs("1900-01-01T00:00").add(endeZeit?.diff(startZeit) - pauseZeit.diff(dayjs("1900-01-01T00:00")),'milliseconds')).format("HH:mm")}
        </Typography>
        
          </Box>
          </>
      )}
      </>
    // </Box>
  );
}
